<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index: 999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar
      dark
      :color="dataQuotation.estado == 'CANCELADA' ? 'red' : 'primary'"
      dense
    >
      <v-row>
        <v-col cols="12" md="8" sm="12" class="caption text-md-h6">
          {{ dataRequestQuotation.cotizacion.partyNombres.toUpperCase() }}
          {{ dataRequestQuotation.cotizacion.partyCedula }} -
          {{ dataRequestQuotation.cotizacion.quoteId }} -
          {{ statusQuotation }} - {{ dateQuotation }}
        </v-col>
        <v-col class="d-none d-md-block text-right caption text-md-h6" md="4">
          <v-icon>mdi-monitor</v-icon>
          <span style="position: relative; top: 2px; margin-right: 25px"
            >POS 1</span
          >
          <v-icon>mdi-account-circle</v-icon>
          <span style="position: relative; top: 2px">{{
            this.user.user_ligin_id.toUpperCase()
          }}</span>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>
    <v-tabs v-model="tab" align-with-title dense grow>
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab> <v-icon class="mr-2">mdi-receipt</v-icon> DATOS GENERALES </v-tab>
      <v-tab
        v-show="whitShipping"
        @click="pointShippingMap"
        v-if="$route.params.work_effort_id == undefined"
      >
        <v-icon class="mr-2">mdi-truck-fast</v-icon> DATOS DE ENVÍO
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">mdi-cash-multiple</v-icon> DATOS DE PAGO
      </v-tab>
      <v-tab
        :disabled="!enabledPayments"
        v-if="$route.params.work_effort_id == undefined"
      >
        <v-icon class="mr-2">mdi-inbox-arrow-up</v-icon> DESPACHO
      </v-tab>
    </v-tabs>
    <v-toolbar
      dense
      style="height: 70px; width: 100%; overflow: auto"
      id="header-buttons"
    >
      <!-- <v-btn 
                        small 
                        color="secondary"
                        class="mr-2"
                        @click="returnQuote"
                        v-if="$route.params.work_effort_id == undefined && returnQuotation"
                    >
                        <span> <v-icon>mdi-pencil</v-icon> Editar cotización</span>
                    </v-btn> -->
      <v-btn
        small
        color="blue-grey darken-1 white--text"
        class="mx-2"
        @click="dialogCancelQuotation = true"
        v-if="$route.params.work_effort_id == undefined && btnCancelQuote"
      >
        <v-icon>mdi-cancel</v-icon> Cancelar
      </v-btn>
      <v-btn
        small
        color="blue-grey darken-1 white--text"
        class="mx-2"
        @click="dialogInformation('statusInformation')"
      >
        <v-icon>mdi-format-list-checks</v-icon> Estados
      </v-btn>
      <v-btn
        v-if="dataQuotation.estado != 'CANCELADA'"
        small
        color="blue-grey darken-1 white--text"
        class="mx-2"
        @click="dialogInformation('invoceInformation')"
      >
        <v-icon>mdi-clipboard-text</v-icon> Info Facturación
      </v-btn>
      <v-btn
        small
        color="primary"
        class="mx-2"
        @click="goPaymentDetails"
        v-if="refreshQuote"
      >
        <v-icon>mdi-account-check</v-icon> Verificar aprobación
      </v-btn>
      <v-btn
        small
        color="primary"
        class="mx-2"
        v-if="paymentsStep"
        @click="goPaymentDetails"
      >
        <v-icon>mdi-arrow-right-bold</v-icon> Siguiente
      </v-btn>
      <v-btn
        v-if="$route.params.work_effort_id == undefined"
        small
        color="primary"
        class="mx-2"
        @click="copyQuote"
      >
        <v-icon>mdi-content-copy</v-icon> Copiar cotización
      </v-btn>
      <v-btn
        small
        color="info"
        class="mx-2"
        @click="printLabel"
        target="_blank"
        v-if="
          $route.params.work_effort_id == undefined &&
          enabledPayments &&
          shippingType == 'SHIPPING'
        "
      >
        <v-icon class="mr-1">mdi-barcode-scan</v-icon> Etiqueta
      </v-btn>
      <v-btn
        small
        color="info"
        class="mx-2"
        @click="printCommand(1)"
        target="_blank"
        v-if="$route.params.work_effort_id == undefined && enabledPayments"
      >
        <v-icon class="mr-1">mdi-file-document</v-icon> Reimprimir Comanda
      </v-btn>
      <v-btn
        small
        color="primary"
        class="mx-2"
        @click="dispatchQuote"
        v-if="enableDispatch"
      >
        <v-icon>mdi-cube-send</v-icon> Entregar
      </v-btn>
      <v-btn
        small
        color="primary"
        class="mx-2"
        @click="printSalesReceipt"
        v-if="dataQuotation.estado == 'FACTURADO'"
      >
        <v-icon>mdi-printer</v-icon> Reimprimir ticket
      </v-btn>
      <v-btn
        v-if="
          dataQuotation.claveAcceso != '' && dataQuotation.claveAcceso != null
        "
        small
        color="primary"
        class="mx-2"
        link
        :href="`https://sufactura.ec/descargar-ride?claveAcceso=${dataQuotation.claveAcceso}`"
      >
        <v-icon>mdi-download</v-icon> Descargar factura
      </v-btn>
      <v-btn small color="info" class="mx-2" @click="downloadQuote">
        <v-icon>mdi-download</v-icon> Descargar cotización
      </v-btn>

      <v-btn
        v-if="dataQuotation.oportunidad_id != ''"
        small
        color="info"
        class="mx-2"
        :to="`/crm_cotizacion?sop_id=${dataQuotation.oportunidad_id}&quote_id=${this.dataQuotation.quoteId}`"
      >
        <v-icon>mdi-handshake</v-icon> Oportunidad
      </v-btn>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <div></div>
      <v-tab-item>
        <v-card flat>
          <v-col cols="12">
            <v-form ref="form_quotation">
              <v-row>
                <v-col cols="12" md="5">
                  <v-card>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <td colspan="2" class="td-head-custom text-center">
                              <v-icon color="white">mdi-account-circle</v-icon>
                              Datos del cliente
                            </td>
                          </tr>
                        </thead>
                        <tbody id="client-data">
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-18px mdi-store</v-icon>
                              Establecimiento
                            </td>
                            <td>
                              <v-autocomplete
                                v-model="establishmentSelected"
                                class="mt-0 input-quotation"
                                style="height: 25px"
                                item-text="store_name"
                                item-value="product_store_id"
                                dense
                                :items="productStore"
                                :disabled="!btnCancelQuote"
                                :rules="ruleRequired"
                                :loading="loadingEstablishmentSelected"
                                @change="changeUbicationSelected"
                                hide-details
                              >
                                <template v-slot:no-data>
                                  <span class="pl-2"
                                    >Sin ubicaciones registradas</span
                                  >
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-account-circle</v-icon> Cliente
                            </td>
                            <td>
                              <a
                                target="_blank"
                                :href="`/clientes/${dataQuotation.partyId}`"
                              >
                                {{ dataQuotation.partyNombres }}
                                {{ dataQuotation.partyCedula }} -
                                {{ dataQuotation.partyId }}
                              </a>
                              <v-icon
                                style="float: right"
                                @click="openDialogCreditCard({})"
                                >mdi-credit-card-plus</v-icon
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-account-settings</v-icon> Paciente
                            </td>
                            <td>
                              <v-autocomplete
                                v-model="patientSelected"
                                class="mt-0 input-quotation"
                                item-text="name"
                                item-value="party_id"
                                style="height: 25px"
                                dense
                                :disabled="!btnCancelQuote"
                                clearable
                                append-outer-icon="mdi-plus-circle-outline"
                                :items="patients"
                                :loading="loadingPatientSelected"
                                @change="changePatientSelected"
                                @click:append-outer="
                                  dialogInformation('patient')
                                "
                              ></v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-18px mdi-cellphone</v-icon> Teléfono
                            </td>
                            <td>
                              <v-select
                                v-model="phoneSelected"
                                class="mt-0 input-quotation"
                                style="height: 25px"
                                item-value="cmId2"
                                item-text="phone"
                                dense
                                append-outer-icon="mdi-plus-circle-outline"
                                :disabled="!btnCancelQuote"
                                :loading="loadingPhoneSelected"
                                :items="phones"
                                :rules="ruleRequired"
                                @change="changePhoneSelected"
                                @click:append-outer="dialogInformation('phone')"
                              >
                                <template v-slot:no-data>
                                  <span class="pl-2"
                                    >Sin teléfonos registrados</span
                                  >
                                </template>
                              </v-select>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-medical-bag</v-icon> Prescriptor
                            </td>
                            <td>
                              <v-autocomplete
                                v-model="prescriberSelected"
                                class="mt-0 input-quotation"
                                item-text="nombres"
                                item-value="partyid"
                                style="height: 25px"
                                dense
                                clearable
                                :disabled="!btnCancelQuote"
                                :items="relatedPrescribers"
                                :loading="loadingPrescriptorSelected"
                                @change="changePrescriptorSelected"
                              >
                                <template v-slot:append-outer>
                                  <v-icon @click="dialogPrescriber = true"
                                    >mdi-account-switch</v-icon
                                  >
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-18px mdi-cash-100</v-icon> Forma de
                              pago
                            </td>
                            <td>
                              <v-autocomplete
                                v-model="wayToPaySelected"
                                :items="filterWayToPays"
                                item-value="payment_method_type_id"
                                item-text="descripcion"
                                class="mt-0 input-quotation"
                                style="height: 25px"
                                dense
                                :disabled="
                                  $route.params.work_effort_id != undefined ||
                                  returnQuotation ||
                                  !btnCancelQuote
                                "
                                :loading="loadingWayToPaySelected"
                                @change="changeWayToPay"
                              >
                              </v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-18px mdi-clock-alert</v-icon> Plazo
                            </td>
                            <td>
                              <v-select
                                v-model="deadlineSelected"
                                :items="deadLines"
                                item-value="plazo_id"
                                item-text="nombre_plazo"
                                class="mt-0 input-quotation"
                                style="height: 25px"
                                :disabled="!btnCancelQuote"
                                dense
                                :loading="loadingDeadlineSelected"
                                @change="changeDeadlineSelected"
                              ></v-select>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-18px mdi-email-outline</v-icon> Email
                            </td>
                            <td>
                              <v-select
                                v-model="emailSelected"
                                item-text="email"
                                item-value="emailId"
                                class="mt-0 input-quotation"
                                style="height: 25px"
                                :disabled="!btnCancelQuote"
                                :items="emails"
                                :loading="loadingEmailSelected"
                                dense
                                append-outer-icon="mdi-plus-circle-outline"
                                @click:append-outer="dialogInformation('email')"
                                @change="changeEmailSelected"
                              >
                              </v-select>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-18px mdi-truck-delivery</v-icon> Tipo
                              de envío
                            </td>
                            <td>
                              <v-select
                                v-model="shippingType"
                                item-value="id"
                                item-text="name"
                                class="mt-0 input-quotation"
                                style="height: 25px"
                                dense
                                :disabled="
                                  $route.params.work_effort_id != undefined ||
                                  returnQuotation ||
                                  !btnCancelQuote
                                "
                                :items="shippingTypes"
                                :rules="ruleRequired"
                                @change="changeShippingType"
                              >
                                <template
                                  v-slot:append-outer
                                  v-if="!whitShipping"
                                >
                                  <v-icon
                                    @click="dialogInformation('direction')"
                                    >mdi-plus-circle-outline</v-icon
                                  >
                                </template>
                              </v-select>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon
                                >mdi-18px mdi-comment-multiple-outline</v-icon
                              >
                              Descripción
                            </td>
                            <td>
                              <v-text-field
                                v-model="description"
                                class="mt-0 input-quotation"
                                style="height: 25px"
                                dense
                                :disabled="!btnCancelQuote"
                                @keyup="storeAditionalInfo"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Acuerdo</td>
                            <td>{{ dataQuotation.agreementId }}</td>
                          </tr>
                          <tr v-if="psychotropic">
                            <td class="td-custom">Psicotrópicos</td>
                            <td>
                              <h2>
                                <b
                                  >EN ESTE PEDIDO EXISTEN PRODUCTOS
                                  PSICOTRÓPICOS. EXIJA UNA RECETA ESPECIAL.
                                </b>
                              </h2>
                              <img
                                style="float: right"
                                src="../../assets/images/psicotropicos.png"
                                width="30px"
                                height="30px"
                              />
                            </td>
                          </tr>
                          <tr v-if="productControlled">
                            <td class="td-custom">Controlados</td>
                            <td>
                              <h2>
                                <b
                                  >EN ESTE PEDIDO EXISTEN PRODUCTOS CONTROLADOS.
                                  EXIJA UNA RECETA.</b
                                >
                              </h2>
                              <img
                                style="float: right"
                                src="../../assets/images/controlados.png"
                                width="30px"
                                height="30px"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <td colspan="2" class="td-head-custom text-center">
                              <v-icon color="white">mdi-cash-100</v-icon> Montos
                              de pago
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- <tr>
                                                        <td class="td-custom">Sub Total</td>
                                                        <td>${{paymentAmounts.subTotal}}</td>
                                                    </tr> -->
                          <tr>
                            <td class="td-custom custom-price">Total PVP</td>
                            <td class="custom-price">
                              ${{ paymentAmounts.totalPvP }}
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Descuento Innofarm</td>
                            <td>${{ paymentAmounts.currentTC }}</td>
                          </tr>

                          <tr>
                            <td class="td-custom">Descuento Transferencia</td>
                            <td>${{ paymentAmounts.cash }}</td>
                          </tr>

                          <tr>
                            <td class="td-custom custom-price">Total Ahorro</td>
                            <td class="custom-price">
                              ${{ paymentAmounts.totalSaving }}
                            </td>
                          </tr>

                          <tr>
                            <td class="td-custom">Sub Total IVA 0</td>
                            <td>
                              ${{
                                (
                                  parseFloat(paymentAmounts.subTotalImp0) +
                                  parseFloat(priceShipment)
                                ).toFixed(2)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Sub Total IVA 12%</td>
                            <td>
                              ${{
                                (
                                  parseFloat(paymentAmounts.subTotalImp) +
                                  parseFloat(paymentAmounts.totalImp)
                                ).toFixed(2)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Total IVA</td>
                            <td>${{ paymentAmounts.totalImp.toFixed(2) }}</td>
                          </tr>
                          <tr>
                            <td class="td-custom">Costo envío</td>
                            <td>
                              $ {{ parseFloat(priceShipment).toFixed(2) }}
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom custom-price">Total</td>
                            <td class="custom-price">
                              ${{ paymentAmounts.total }}
                              <span
                                :style="`height:17px;width:17px;border-radius: 60px;background:${quoteMarginColor};position: relative;top: 3px;right: 7px;float: left;`"
                              ></span>
                            </td>
                          </tr>

                          <!--
                                                   
                                                    <tr>
                                                        <td class="td-custom">Descuento TC diferido</td>
                                                        <td>${{paymentAmounts.deferredTD}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="td-custom">Descuento Tarjeta débito</td>
                                                        <td>${{paymentAmounts.debitCard}}</td>
                                                    </tr>

                                                    -->
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                  <v-card class="mt-4" flat> </v-card>
                </v-col>
                <v-col>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <td colspan="2" class="td-head-custom text-center">
                            <v-icon color="white">mdi-ticket-percent</v-icon>
                            Cupones
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <v-data-table
                          :headers="headerCupones"
                          :items="cupones"
                          class="elevation-1"
                          small
                          hide-default-footer
                        >
                          <template v-slot:item.opciones_cupon="{ item }">
                            <v-btn
                              small
                              text
                              title="borrar este código"
                              color="error"
                              @click="borrarCodigoPromo(item)"
                              ><v-icon>mdi-delete</v-icon></v-btn
                            >
                          </template>
                        </v-data-table>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-btn
                    class="mt-4 mx-4"
                    small
                    color="orange"
                    dark
                    @click="abrirDialogCupones"
                    ><v-icon left>mdi-plus</v-icon>Ingresar cupón</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <v-card>
                    <v-row class="py-0 px-4">
                      <v-col cols="12" md="8" class="py-0">
                        <v-text-field
                          v-model="product"
                          placeholder="Buscar productos por código, nombre o descripción"
                          dense
                          append-icon="mdi-magnify"
                          @keyup.enter="dialogInformation('products')"
                          @click:append="dialogInformation('products')"
                          :disabled="!btnCancelQuote"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-simple-table dense class="mx-2">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <td
                                  :colspan="recipColumn ? 14 : 13"
                                  class="td-head-custom text-center"
                                >
                                  <v-icon color="white"
                                    >mdi-cart-outline</v-icon
                                  >
                                  Detalle de venta
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  v-if="!returnQuotation && btnCancelQuote"
                                  style="width: 1rem"
                                  class="td-custom"
                                ></td>
                                <td style="width: 3rem" class="td-custom">
                                  Sec
                                </td>
                                <td class="td-custom">Producto</td>
                                <td class="td-custom" style="width: 500px">
                                  Descripción
                                </td>
                                <td class="td-custom">Marca</td>
                                <td class="td-custom">Cantidad</td>
                                <td class="td-custom">P. cotizado</td>
                                <td class="td-custom">Descuento</td>
                                <td class="td-custom">% Iva</td>
                                <td class="td-custom">Total</td>
                                <td class="td-custom">Margen</td>
                                <td class="td-custom" v-if="recipColumn">
                                  Nº Receta
                                </td>
                                <td class="td-custom">Inventario local</td>
                                <td class="td-custom">Inv. otros locales</td>
                              </tr>
                              <tr
                                v-for="(item, x) in productsQuotation"
                                :key="x"
                              >
                                <td
                                  v-if="!returnQuotation && btnCancelQuote"
                                  class="p-0"
                                >
                                  <v-btn
                                    small
                                    icon
                                    @click="deleteProductSelected(item)"
                                    v-if="
                                      (item.isPromo == '1' ||
                                        item.isPromo == '0' ||
                                        item.isPromo == null) &&
                                      btnCancelQuote
                                    "
                                  >
                                    <v-icon color="red"
                                      >mdi-delete-forever</v-icon
                                    >
                                  </v-btn>
                                </td>
                                <td>{{ item.seqId }}</td>
                                <td>{{ item.product }}</td>
                                <td>{{ item.description }}</td>
                                <td>{{ item.brand }}</td>
                                <td>
                                  <v-text-field
                                    :disabled="
                                      $route.params.work_effort_id !=
                                        undefined ||
                                      returnQuotation ||
                                      !btnCancelQuote
                                    "
                                    v-model="item.quantity"
                                    class="mt-0 input-quotation text-center"
                                    style="height: 25px; width: 50px"
                                    type="number"
                                    min="1"
                                    dense
                                    @change="updateTablePrices(item.seqId)"
                                    autocomplete="off"
                                  />
                                </td>
                                <td>
                                  <span
                                    :style="`height:17px;width:17px;border-radius: 60px;background:${item.color};position: relative;top: 6px;right: 7px;float: left;`"
                                  ></span>
                                  <v-text-field
                                    :disabled="
                                      $route.params.work_effort_id !=
                                        undefined ||
                                      returnQuotation ||
                                      !btnCancelQuote
                                    "
                                    v-model="item.pQuoted"
                                    class="mt-1 input-quotation"
                                    style="height: 25px; width: 70px"
                                    type="number"
                                    min="0"
                                    dense
                                    @change="
                                      updateTablePrices(item.seqId, 'precio')
                                    "
                                    autocomplete="off"
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    :disabled="
                                      $route.params.work_effort_id !=
                                        undefined ||
                                      returnQuotation ||
                                      !btnCancelQuote
                                    "
                                    v-model="item.discount"
                                    class="mt-1 input-quotation"
                                    style="height: 25px; width: 70px"
                                    type="number"
                                    min="0"
                                    dense
                                    @change="
                                      updateTablePrices(item.seqId, 'desc')
                                    "
                                    autocomplete="off"
                                  />
                                </td>
                                <td>{{ item.pctImp }}</td>
                                <td>${{ item.total }}</td>
                                <td>{{ item.margin }}%</td>
                                <td v-if="recipColumn">
                                  <v-text-field
                                    v-if="item.psicotropic == 'SI'"
                                    v-model="item.recip"
                                    class="mt-1 input-quotation"
                                    style="height: 25px; width: 70px"
                                    type="number"
                                    v-mask="'########'"
                                    dense
                                    autocomplete="off"
                                    @keyup="storeRecip(item)"
                                  />
                                </td>
                                <td>{{ item.stock }}</td>
                                <td class="text-center">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="success"
                                        @click="storeInventory(item)"
                                        >mdi-package-variant</v-icon
                                      >
                                    </template>
                                    <span>Ver inventario en otras tiendas</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-dialog
            v-model="dialogDataQuotation"
            persistent
            :max-width="defaultDialogInformation.maxWidthDialog"
          >
            <v-card>
              <CapturePhone
                v-if="dialogosInformation.addPhoneClient"
                @close="closeDialogInformation"
                @storePhone="storePhone"
              />
              <CaptureDirection
                :attnName="
                  dataDirection.attnName != null
                    ? dataDirection.attnName
                    : dataQuotation.partyNombres
                "
                v-if="dialogosInformation.addDirection"
                @close="closeDialogInformation"
                :action="
                  shippingType.trim() == 'NO_SHIPPING' ? 'billing' : 'shipping'
                "
                @storeDirection="storeDirection"
                @point="point"
              />
              <CaptureEmail
                v-if="dialogosInformation.addEmail"
                @close="closeDialogInformation"
                @storeEmail="storeEmail"
              />
              <CaptureProducts
                v-if="dialogosInformation.addProducts"
                :products="dataTable"
                :loadingTable="loadingTableProducts"
                :partyId="dataRequestQuotation.cotizacion.partyId"
                :enabledAddProduct="!enabledPayments"
                @close="closeDialogInformation"
                @updateProducts="updateProducts"
                @updateLoadingTable="updateLoadingTable"
                @addProductSelected="addProductSelected"
                @storeInventory="storeInventory"
                @infoProducto="infoProducto"
                @infoPromo="infoPromo"
              />
              <CapturePatient
                v-if="dialogosInformation.patient"
                @close="closeDialogInformation"
                @storePatient="storePatient"
              />
              <v-col cols="12" v-if="dialogosInformation.invoceInformation">
                <v-alert type="success" dense>
                  INFORMACIÓN DE FACTURACIÓN</v-alert
                >
                <v-col cols="12">
                  <v-card>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <td colspan="2" class="td-head-custom text-center">
                              <v-icon color="white">mdi-file-document</v-icon>
                              Información general
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-account-circle</v-icon> Cliente
                              factura
                            </td>
                            <td>
                              <v-autocomplete
                                v-model="dataQuotation.partyId"
                                class="mt-0 input-quotation"
                                item-text="name"
                                item-value="party_id"
                                style="height: 25px"
                                dense
                                append-outer-icon="mdi-plus-circle-outline"
                                :disabled="actionData == 3"
                                :items="clients"
                                @change="changeClientSelected"
                                @click:append-outer="dialogClient = true"
                              ></v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon>mdi-directions</v-icon> Dir. de factura
                            </td>
                            <td>
                              <v-autocomplete
                                v-model="dataQuotation.direccionId"
                                class="mt-0 input-quotation"
                                item-text="name"
                                item-value="direccionId"
                                style="height: 25px"
                                dense
                                append-outer-icon="mdi-plus-circle-outline"
                                :items="billingAddress"
                                @change="changeBillingAddress"
                                @click:append-outer="
                                  dialogBillingAddress = true
                                "
                              >
                              </v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon
                                >mdi-18px mdi-comment-multiple-outline</v-icon
                              >
                              Información 1
                            </td>
                            <td>
                              <v-text-field
                                v-model="info1"
                                class="mt-0 input-quotation"
                                style="height: 25px"
                                :disabled="actionData == 3"
                                dense
                                @keyup="storeAditionalInfo"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon
                                >mdi-18px mdi-comment-multiple-outline</v-icon
                              >
                              Información 2
                            </td>
                            <td>
                              <v-text-field
                                v-model="info2"
                                class="mt-0 input-quotation"
                                style="height: 25px"
                                :disabled="actionData == 3"
                                dense
                                @keyup="storeAditionalInfo"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">
                              <v-icon
                                >mdi-18px mdi-comment-multiple-outline</v-icon
                              >
                              Información 3
                            </td>
                            <td>
                              <v-text-field
                                v-model="info3"
                                class="mt-0 input-quotation"
                                style="height: 25px"
                                dense
                                :disabled="actionData == 3"
                                @keyup="storeAditionalInfo"
                              ></v-text-field>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <td colspan="2" class="td-head-custom text-center">
                              <v-icon color="white">mdi-file-document</v-icon>
                              Última factura
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="td-custom">Factura:</td>
                            <td>
                              <a
                                target="_blank"
                                :href="`https://sufactura.ec/descargar-ride?claveAcceso=${
                                  typeof lastBill[0] != 'undefined'
                                    ? lastBill[0].clave_acceso
                                    : '#'
                                }`"
                                >{{
                                  typeof lastBill[0] != "undefined"
                                    ? lastBill[0].invoice_id
                                    : ""
                                }}</a
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Cotización:</td>
                            <td>
                              <a
                                target="_blank"
                                :href="
                                  typeof lastBill[0] != 'undefined'
                                    ? lastBill[0].quote_id
                                    : '#'
                                "
                                >{{
                                  typeof lastBill[0] != "undefined"
                                    ? lastBill[0].quote_id
                                    : "#"
                                }}</a
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Fecha:</td>
                            <td>
                              {{
                                typeof lastBill[0] != "undefined"
                                  ? lastBill[0].fecha_factura
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Prescriptor:</td>
                            <td>
                              {{
                                typeof lastBill[0] != "undefined"
                                  ? lastBill[0].prescriptor
                                  : ""
                              }}
                            </td>
                          </tr>

                          <tr>
                            <td class="td-custom">Dirección:</td>
                            <td>
                              {{
                                typeof lastBill[0] != "undefined" &&
                                lastBill[0].direccion != "" &&
                                lastBill[0].direccion != null
                                  ? lastBill[0].direccion
                                  : "Sin envío"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Monto envío:</td>
                            <td>
                              ${{
                                typeof lastBill[0] != "undefined"
                                  ? lastBill[0].envio
                                  : "0.00"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Método pago:</td>
                            <td>
                              {{
                                typeof lastBill[0] != "undefined"
                                  ? lastBill[0].tipo_pago
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Descripción:</td>
                            <td>
                              {{
                                typeof lastBill[0] != "undefined"
                                  ? lastBill[0].descripcion
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="td-custom">Sub total</td>
                            <td>${{ subTotalLastBill }}</td>
                          </tr>
                          <tr>
                            <td class="td-custom">Total:</td>
                            <td>${{ totalLastBill }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <td colspan="4" class="td-head-custom text-center">
                              <v-icon color="white">mdi-cube-send</v-icon>
                              Productos
                              <div class="float-md-right">
                                <v-btn
                                  color="primary"
                                  small
                                  style="height: 21px"
                                  @click="dialogShoppingHistory = true"
                                  v-if="lastBill.length"
                                >
                                  <v-icon>mdi-history</v-icon> Historial
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Producto</td>
                            <td>Cant</td>
                            <td>Precio</td>
                            <td>Total</td>
                          </tr>
                          <tr v-for="(bill, x) in lastBill" :key="x">
                            <td>{{ bill.internal_name }}</td>
                            <td>{{ parseFloat(bill.cant_item).toFixed(2) }}</td>
                            <td>{{ parseFloat(bill.amount).toFixed(2) }}</td>
                            <td>
                              {{
                                parseFloat(
                                  bill.cant_item * bill.amount
                                ).toFixed(2)
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-col col="12" class="text-center">
                      <v-btn
                        small
                        color="secondary"
                        @click="closeDialogInformation(false)"
                      >
                        <v-icon>mdi-cancel</v-icon> Cerrar
                      </v-btn>
                    </v-col>
                  </v-card>
                </v-col>
              </v-col>
              <v-col cols="12" v-if="dialogosInformation.statusInformation">
                <v-alert type="success" dense>
                  LOGS DE ESTSATUS DE LA COTIZACIÓN</v-alert
                >
                <v-col cols="12">
                  <v-card>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <td class="td-head-custom">FECHA</td>
                            <td class="td-head-custom">USUARIO</td>
                            <td class="td-head-custom">ESTADO</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(status, x) in dataQuotation.status"
                            :key="x"
                          >
                            <td>{{ status.status_datetime }}</td>
                            <td>{{ status.status_user_login }}</td>
                            <td>{{ status.estado }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-col col="12" class="text-center">
                      <v-btn
                        small
                        color="secondary"
                        @click="closeDialogInformation(false)"
                      >
                        <v-icon>mdi-cancel</v-icon> Cerrar
                      </v-btn>
                    </v-col>
                  </v-card>
                </v-col>
              </v-col>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogShoppingHistory"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-simple-table dense class="mx-2">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td style="width: 450px" class="td-custom">Producto</td>
                      <td style="width: 200px" class="td-custom">Fecha</td>
                      <td class="td-custom" style="width: 30px">Cantidad</td>
                      <td class="td-custom">Valor</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, x) in dataRequestQuotation.invoices.filter(
                        (obj) => obj.invoice_item_type_id != 'ITM_SALES_TAX'
                      )"
                      :key="x"
                    >
                      <td style="width: 450px">{{ item.internal_name }}</td>
                      <td style="width: 200px">{{ item.fecha_factura }}</td>
                      <td style="width: 30px">
                        {{ parseFloat(item.cant_item).toFixed(2) }}
                      </td>
                      <td>{{ parseFloat(item.amount).toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-col col="12" class="text-center">
                <v-btn
                  small
                  color="secondary"
                  @click="dialogShoppingHistory = false"
                >
                  <v-icon>mdi-cancel</v-icon> Cerrar
                </v-btn>
              </v-col>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogStoreInventory" persistent max-width="800">
            <v-card>
              <v-alert dense type="success" icon="mdi-package-variant">
                INVENTARIO POR TIENDAS
              </v-alert>
              <v-data-table
                :headers="inventoryLotsHeaders"
                :items="storeInventories"
                :single-expand="true"
                :expanded.sync="expandedInventory"
                item-key="product_store_id"
                show-expand
                class="elevation-1"
              >
                <template v-slot:item.product_name="{ item }">
                  {{ inventoryItemSelected.description }}
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="my-3"></div>
                  </td>
                </template>
              </v-data-table>
              <!-- <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <td class="text-center font-weight-bold td-custom" >TIENDA</td>
                                            <td class="text-center font-weight-bold td-custom">PROCUTO</td>
                                            <td class="text-center font-weight-bold td-custom">INVENTARIO ACTUAL</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(si,x) in storeInventories"
                                            :key=x
                                        >
                                            <td class="text-center">{{si.store_name}}</td>
                                            <td class="text-center" style="width:400px">{{inventoryItemSelected.description}}</td>
                                            <td class="text-center">{{si.inventory}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table> -->
              <v-col col="12" class="text-center">
                <v-btn
                  small
                  color="secondary"
                  @click="dialogStoreInventory = false"
                >
                  <v-icon>mdi-cancel</v-icon> Cerrar
                </v-btn>
              </v-col>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogPrescriber" persistent max-width="600">
            <v-card>
              <v-alert dense type="success" icon="mdi-account-convert"
                >PRESCRIPTORES DISPONIBLES
              </v-alert>

              <v-data-table
                :headers="headersPrescribers"
                :items="
                  prescribers.filter((obj) => {
                    return (
                      typeof dataQuotation.prescribers.find(
                        (e) => e.partyid == obj.partyid
                      ) == 'undefined'
                    );
                  })
                "
                :search="searchPrescribers"
                :key="`partyid`"
                loading-text="Cargando prescriptores..."
                item-key="partyid"
                class="elevation-1"
                dense
              >
                <template v-slot:top>
                  <v-col md="12">
                    <v-text-field
                      dense
                      v-model="searchPrescribers"
                      label="Buscar prescriptor"
                      autofocus
                    />
                  </v-col>
                </template>
                <template v-slot:item.select="{ item }">
                  <v-icon color="success" @click="relatePrescriber(item)"
                    >mdi-floppy</v-icon
                  >
                </template>
                <template v-slot:footer>
                  <v-col class="text-center">
                    <v-btn @click="dialogPrescriber = !dialogPrescriber">
                      <v-icon>mdi-cancel</v-icon> CERRRAR
                    </v-btn>
                  </v-col>
                </template>
              </v-data-table>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogCancelQuotation"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-alert dense type="warning" icon="mdi-alert-outline"
                >Está seguro de cancelar la cotización</v-alert
              >
              <v-form ref="form_cancel_quotation">
                <v-col cols="12">
                  <v-select
                    v-model="reasonCancel"
                    :items="reasonsCancel"
                    label="Motivo de cancelación"
                    item-value="motivo_id"
                    item-text="descripcion"
                    outlined
                    :rules="ruleCreditCard"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    v-model="descriptionCancel"
                    label="Descripción"
                    :rules="ruleCreditCard"
                    dense
                  ></v-textarea>
                </v-col>
                <v-col class="text-center" color="success">
                  <v-btn color="success" class="mx-1" @click="cancelQuote">
                    <v-icon>mdi-floppy</v-icon> Guardar
                  </v-btn>
                  <v-btn @click="closeDialogCancelQuotation">
                    <v-icon>mdi-cancel</v-icon> CERRAR
                  </v-btn>
                </v-col>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog
            max-width="600px"
            v-model="dialogClient"
            style="background: white"
          >
            <CaptureClient
              @storeClient="storeClient"
              @close="dialogClient = false"
            />
          </v-dialog>
          <v-dialog
            max-width="600px"
            v-model="dialogBillingAddress"
            style="background: white"
          >
            <v-card>
              <CaptureDirection
                attnName=""
                @close="dialogBillingAddress = false"
                action="billing"
                @storeDirection="storeDirection"
              />
            </v-card>
          </v-dialog>

          <!-- info adicional -->
          <v-dialog v-model="dialogInfoProd" persistent max-width="800">
            <v-card>
              <v-alert dense type="success" icon="mdi-package-variant"
                >INFO. PRODUCTO</v-alert
              >
              <v-list subheader three-line>
                <v-list-item v-if="mostrarPromo == true">
                  <v-list-item-content
                    v-for="(item, i) in datosPromo"
                    :key="i"
                    v-if="item.product_store_id == establishmentSelected"
                  >
                    <v-list-item-title>Promocion</v-list-item-title>
                    <v-list-item-subtitle
                      v-text="
                        item.nombre_promo.replace(/{/, '').replace(/}/, '')
                      "
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-simple-table dense class="mx-2" v-if="mostrarPromo == false">
                <template v-slot:default>
                  <tbody>
                    <tr v-if="infoProd.psico != ''">
                      <td class="td-custom">Nombre</td>
                      <td>
                        <h2>
                          {{ infoProd.product_name_web }}
                        </h2>
                      </td>
                    </tr>

                    <tr v-if="infoProd.psico != null">
                      <td class="td-custom">Producto psicotrópico</td>
                      <td>
                        <b class="px-2 mt-2"
                          >Este es un producto psicotrópico, Exigir receta
                          especial</b
                        >
                        <img
                          style="float: left"
                          src="../../assets/images/psicotropicos.png"
                          width="30px"
                          height="30px"
                        />
                      </td>
                    </tr>

                    <tr v-if="infoProd.controlado != null">
                      <td class="td-custom">Producto controlado</td>
                      <td>
                        <b class="px-2 mt-2"
                          >Este es un producto controlado , exigir receta</b
                        >
                        <img
                          style="float: left"
                          src="../../assets/images/controlados.png"
                          width="30px"
                          height="30px"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td class="td-custom">Principio activo</td>
                      <td class="">{{ infoProd.principio_activo }}</td>
                    </tr>
                    <tr>
                      <td class="td-custom">Forma farmaceutica</td>
                      <td class="">{{ infoProd.forma_farmaceutica }}</td>
                    </tr>

                    <tr>
                      <td class="td-custom">Concentración</td>
                      <td class="">
                        {{
                          infoProd.concent_desc +
                          " (" +
                          infoProd.concent_abrev +
                          ")"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td class="td-custom">Descripción</td>
                      <td class="" v-html="infoProd.short_description"></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-col col="12" class="text-center">
                <v-btn small color="secondary" @click="dialogInfoProd = false">
                  <v-icon>mdi-cancel</v-icon> Cerrar
                </v-btn>
              </v-col>
            </v-card>
          </v-dialog>
          <!-- dialog info adicional -->
        </v-card>
      </v-tab-item>
      <v-tab-item
        v-show="whitShipping"
        v-if="$route.params.work_effort_id == undefined"
        class="mt-3"
      >
        <v-card flat>
          <v-row>
            <v-col cols="12" md="8">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td colspan="2" class="td-head-custom text-center">
                        <v-icon color="white">mdi-truck</v-icon> Detalle del
                        envio
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="td-custom">Dirección</td>
                      <td>
                        <v-select
                          v-model="directionSelected"
                          class="mt-0 input-quotation"
                          item-value="direccionId"
                          item-text="name"
                          style="height: 25px"
                          dense
                          append-outer-icon="mdi-plus-circle-outline"
                          :items="directions"
                          :disabled="
                            $route.params.work_effort_id != undefined ||
                            returnQuotation ||
                            !btnCancelQuote
                          "
                          :rules="ruleRequired"
                          @click:append-outer="dialogInformation('direction')"
                          @change="changeDireccionSelected"
                        >
                          <template v-slot:no-data>
                            <span class="pl-2">Sin dirección registrada</span>
                          </template>
                        </v-select>
                      </td>
                    </tr>
                    <tr>
                      <td class="td-custom">Envío</td>
                      <td>
                        <v-select
                          v-model="shippingMethodSelected"
                          item-value="shipmentMethodId"
                          item-text="description"
                          class="mt-0 input-quotation"
                          style="height: 25px"
                          dense
                          :items="dataRequestQuotation.metodosEnvio"
                          :rules="ruleRequired"
                          :disabled="
                            $route.params.work_effort_id != undefined ||
                            returnQuotation ||
                            !btnCancelQuote
                          "
                          :loading="loadingShippingMethodSelected"
                          @change="
                            changeShippingMethodSelected(true, true, true)
                          "
                          return-object
                        >
                          <template v-slot:append-outer v-if="!whitShipping">
                            <v-icon @click="dialogInformation('direction')"
                              >mdi-plus-circle-outline</v-icon
                            >
                          </template>
                        </v-select>
                      </td>
                    </tr>
                    <tr>
                      <td class="td-custom">Atiende</td>
                      <td>
                        <v-text-field
                          v-model="attnName"
                          dense
                          class="mt-0 input-quotation"
                          style="height: 25px"
                          :rules="ruleRequired"
                          :disabled="
                            $route.params.work_effort_id != undefined ||
                            !btnCancelQuote
                          "
                          @keyup="storeInfohipping"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="td-custom">Teléfono</td>
                      <td>
                        <v-text-field
                          v-model="tlfAttnName"
                          dense
                          class="mt-0 input-quotation"
                          style="height: 25px"
                          :rules="ruleRequired"
                          :disabled="
                            $route.params.work_effort_id != undefined ||
                            !btnCancelQuote
                          "
                          @keyup="storeInfohipping"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="td-custom">Instrucciones</td>
                      <td>
                        <v-text-field
                          v-model="instructions"
                          class="mt-0 input-quotation"
                          style="height: 25px"
                          dense
                          :disabled="
                            $route.params.work_effort_id != undefined ||
                            !btnCancelQuote
                          "
                          @keyup="storeInfohipping"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="td-custom">Enviar despues de:</td>
                      <td>
                        <v-text-field
                          v-model="sendAfter"
                          type="datetime-local"
                          class="mt-0 input-quotation"
                          style="height: 25px"
                          :disabled="
                            $route.params.work_effort_id != undefined ||
                            !btnCancelQuote
                          "
                          dense
                          @change="storeInfohipping"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="td-custom">Entregar antes de:</td>
                      <td>
                        <v-text-field
                          v-model="deliverBefore"
                          type="datetime-local"
                          class="mt-0 input-quotation"
                          style="height: 25px"
                          :disabled="
                            $route.params.work_effort_id != undefined ||
                            !btnCancelQuote
                          "
                          dense
                          @change="storeInfohipping"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="td-custom">Num Guía:</td>
                      <td>
                        <v-text-field
                          v-model="guia"
                          class="mt-0 input-quotation"
                          style="height: 25px"
                          dense
                          :disabled="
                            $route.params.work_effort_id != undefined ||
                            !btnEdiarGuia
                          "
                          @keyup="storeInfohipping"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="td-custom">Impreso por:</td>
                      <td></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="4">
              <v-card>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <td colspan="2" class="td-head-custom text-center">
                          <v-icon color="white">mdi-cash-100</v-icon> Montos de
                          pago
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr>
                                                <td class="td-custom">Sub Total</td>
                                                <td>${{paymentAmounts.subTotal}}</td>
                                            </tr> -->
                      <tr>
                        <td class="td-custom">Sub Total IVA 0</td>
                        <td>
                          ${{
                            (
                              parseFloat(paymentAmounts.subTotalImp0) +
                              parseFloat(priceShipment)
                            ).toFixed(2)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="td-custom">Sub Total IVA 12%</td>
                        <td>
                          ${{
                            parseFloat(paymentAmounts.subTotalImp) +
                            parseFloat(paymentAmounts.totalImp)
                          }}
                        </td>
                      </tr>
                      <!-- <tr>
                                                <td class="td-custom">Total IVA	</td>
                                                <td>${{paymentAmounts.totalImp}}</td>
                                            </tr> -->
                      <tr>
                        <td class="td-custom">Costo envío</td>
                        <td>$ {{ parseFloat(priceShipment).toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <td class="td-custom custom-price">Total</td>
                        <td class="custom-price">
                          ${{ paymentAmounts.total }}
                          <span
                            :style="`height:17px;width:17px;border-radius: 60px;background:${quoteMarginColor};position: relative;top: 3px;right: 7px;float: left;`"
                          ></span>
                        </td>
                      </tr>
                      <tr>
                        <td class="td-custom custom-price">Total PVP</td>
                        <td class="custom-price">
                          ${{ paymentAmounts.totalPvP }}
                        </td>
                      </tr>
                      <tr>
                        <td class="td-custom custom-price">Total Ahorro</td>
                        <td class="custom-price">
                          ${{ paymentAmounts.totalSaving }}
                        </td>
                      </tr>
                      <tr>
                        <td class="td-custom">Efectivo</td>
                        <td>${{ paymentAmounts.cash }}</td>
                      </tr>
                      <tr>
                        <td class="td-custom">TC corriente</td>
                        <td>${{ paymentAmounts.currentTC }}</td>
                      </tr>
                      <tr>
                        <td class="td-custom">TC diferido</td>
                        <td>${{ paymentAmounts.deferredTD }}</td>
                      </tr>
                      <tr>
                        <td class="td-custom">Tarjeta débito</td>
                        <td>${{ paymentAmounts.debitCard }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12">
              <Map
                :makers="pointShippment"
                :saveClientUbication="true"
                :actionData="
                  $route.params.work_effort_id != undefined ||
                  (!returnQuotation && btnCancelQuote)
                "
                @noUpdatePoint="pointShippingMap"
                @updatePoint="updatePointShipping"
                @setPointShippment="(data) => (pointShippment = [data])"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-dialog v-model="dialogCreditCard" persistent max-width="800">
        <v-card>
          <v-alert dense type="success" icon="mdi-credit-card-plus"
            >NUEVA TARJETA DE CRÉDITO</v-alert
          >
          <v-col col="12">
            <v-form ref="form_data_credit_card">
              <v-row>
                <v-col cols="12" md="6" class="mt-2">
                  <v-select
                    v-model="dataStoreCreditCard.enum_id"
                    :items="dataQuotation.enumCreditCards"
                    item-value="enum_id"
                    item-text="description"
                    label="Tipo de tarjeta"
                    class="mt-0 input-quotation"
                    style="height: 25px"
                    dense
                    :rules="ruleCreditCard"
                    :disabled="dataQuotation.estado == 'FACTURADO'"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataStoreCreditCard.first_name_on_card"
                    dense
                    label="Nombre en tarjeta"
                    :rules="ruleCreditCard"
                    @keyup="readDataCreditCard"
                    autofocus
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataStoreCreditCard.last_name_on_card"
                    dense
                    label="Apellido en tarjeta"
                    :rules="ruleCreditCard"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataStoreCreditCard.card_number"
                    type="number"
                    dense
                    label="Número de tarjeta"
                    :rules="ruleCreditCard"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="dataStoreCreditCard.expire_date"
                    type="month"
                    dense
                    label="Fecha expiración"
                    :rules="ruleCreditCard"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="dataStoreCreditCard.cvv"
                    v-mask="'###'"
                    dense
                    label="Código reverso"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col col="12" class="text-center">
            <v-btn small color="success" @click="storeCreditCard">
              <v-icon>mdi-floppy</v-icon> Guardar
            </v-btn>
            <v-btn small color="secondary" @click="closeDialogCreditCard">
              <v-icon>mdi-cancel</v-icon> Cerrar
            </v-btn>
          </v-col>
        </v-card>
      </v-dialog>
      <v-tab-item class="mt-3">
        <v-card flat>
          <v-dialog
            v-model="dialogCreditCardPaymentData"
            persistent
            max-width="600"
          >
            <v-card>
              <v-alert dense type="success" icon="mdi-credit-card-scan">
                DATOS DE TARJETA DE CRÉDITO Y PAGO
              </v-alert>
              <v-col col="12">
                <v-row>
                  <v-col cols="12">
                    Sub Total IVA 0%:
                    <b>
                      ${{
                        (
                          parseFloat(paymentAmounts.subTotalImp0) +
                          parseFloat(priceShipment)
                        ).toFixed(2)
                      }}
                    </b>
                  </v-col>
                  <v-col cols="12">
                    Sub Total IVA 12%:
                    <b>
                      ${{
                        parseFloat(paymentAmounts.subTotalImp) +
                        parseFloat(paymentAmounts.totalImp)
                      }}
                    </b>
                  </v-col>
                  <v-col cols="12">
                    Tipo de tarjeta:
                    <b>{{ creditCardPaymentData.card_type }}</b>
                  </v-col>
                  <v-col cols="12">
                    Propietaro de la tarjerta:
                    <b
                      >{{ creditCardPaymentData.first_name_on_card }}
                      {{ creditCardPaymentData.last_name_on_card }}</b
                    >
                  </v-col>
                  <v-col cols="12">
                    Número de la tarjeta:
                    <b>{{ creditCardPaymentData.card_number }}</b>
                  </v-col>

                  <v-col cols="12">
                    Código reverso de la tarjeta:
                    <b>{{ creditCardPaymentData.cvv }}</b>
                  </v-col>
                  <v-col cols="12">
                    Fecha de expiración de la tarjeta:
                    <b>{{ creditCardPaymentData.expire_date }}</b>
                  </v-col>
                </v-row>
              </v-col>
              <v-col col="12" class="text-center">
                <v-btn
                  small
                  color="secondary"
                  @click="closeDialogCreditCardData()"
                >
                  <v-icon>mdi-cancel</v-icon> Cerrar
                </v-btn>
              </v-col>
            </v-card>
          </v-dialog>
          <v-row>
            <v-col cols="12" md="9">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td :colspan="creditCard ? 8 : 7" class="td-head-custom">
                        <v-icon color="white">mdi-format-list-checks</v-icon>
                        Detalle de pago
                        <div class="float-md-right">
                          <v-btn
                            color="primary"
                            small
                            style="height: 21px"
                            @click="addPayment"
                            :disabled="
                              $route.params.work_effort_id != undefined
                            "
                          >
                            <v-icon>mdi-plus</v-icon> Agregar pago
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="padding: 0px; width: 20px"
                        class="td-custom text-center"
                      >
                        ID
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Forma de pago
                      </td>
                      <td
                        v-if="creditCard"
                        style="padding: 0px; width: 350px"
                        class="td-custom text-center"
                      >
                        Tarjeta
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Valor
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Referencia
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Estado
                      </td>
                      <td
                        style="padding: 0px; width: 250px"
                        class="td-custom text-center"
                      >
                        Acción
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(payment, x) in dataQuotation.payments" :key="x">
                      <td class="text-center" style="width: 20px">
                        {{ payment.order_payment_preference_id }}
                      </td>
                      <td>
                        <v-select
                          v-model="dataQuotation.payments[x].payment_method_type_id"
                          :items="filterWayToPays.filter(obj => {
                            return dataQuotation.estado == 'FACTURADO' ? ['PLACE_TO_PAY_CC', 'PLACE_TO_PAY_DC'].includes(obj.payment_method_type_id) : obj
                          })"
                          item-value="payment_method_type_id"
                          item-text="descripcion"
                          class="mt-0 input-quotation"
                          style="height: 25px"
                          dense
                          :disabled="
                            $route.params.work_effort_id != undefined ||
                            dataQuotation.payments[x].payment_status ==
                              'AUTHORIZED' ||
                            dataQuotation.payments[x].payment_method_type_id ==
                              'CRUCE_VALOR_ANTICIPO'
                          "
                        />
                      </td>
                      <td v-if="creditCard">
                        <div style="display: inline-flex; width: 100%">
                          <v-select
                            v-if="
                              [
                                'DEBIT_CARD',
                                'CREDIT_CARD',
                                'CREDIT_CARD_DIF_INT',
                                'CRUCE_VALOR_ANTICIPO',
                              ].includes(
                                dataQuotation.payments[x].payment_method_type_id
                              )
                            "
                            v-model="
                              dataQuotation.payments[x].payment_method_id
                            "
                            :items="dataQuotation.creditCards"
                            item-value="payment_method_id"
                            item-text="card_type"
                            class="mt-0 input-quotation"
                            style="height: 25px"
                            dense
                            :disabled="
                              dataQuotation.estado == 'FACTURADO' ||
                              dataQuotation.payments[x].payment_status ==
                                'AUTHORIZED'
                            "
                            @change="setCreditCardQuote(payment)"
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 12px">
                                {{ item.card_type }} {{ item.card_number }}
                              </span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span style="font-size: 12px">
                                {{ item.card_type }} {{ item.card_number }}
                              </span>
                            </template>
                          </v-select>
                          <v-tooltip
                            bottom
                            v-if="
                              payment.payment_method_id != '' &&
                              payment.payment_method_id != null
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                                color="info"
                                :disabled="
                                  tdcData
                                    ? false
                                    : dataQuotation.estado == 'FACTURADO'
                                "
                                @click="openDialogCreditCardData(payment)"
                                >mdi-credit-card</v-icon
                              >
                            </template>
                            <span>Ver datos de la tarjeta</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="success"
                                :disabled="dataQuotation.estado == 'FACTURADO'"
                                @click="openDialogCreditCard(payment)"
                                >mdi-credit-card-plus</v-icon
                              >
                            </template>
                            <span>Crear tarjeta de crédito</span>
                          </v-tooltip>
                        </div>
                      </td>
                      <td>
                        <v-text-field
                          v-model="dataQuotation.payments[x].max_amount"
                          class="mt-0 input-quotation"
                          style="height: 25px"
                          type="number"
                          min="0"
                          dense
                          :disabled="
                            dataQuotation.payments[x].payment_status ==
                              'AUTHORIZED' ||
                            dataQuotation.payments[x].payment_method_type_id ==
                              'CRUCE_VALOR_ANTICIPO'
                          "
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="dataQuotation.payments[x].manual_ref_num"
                          class="mt-0 input-quotation"
                          style="height: 25px"
                          :disabled="
                            dataQuotation.estado == 'FACTURADO' ||
                            dataQuotation.payments[x].payment_method_type_id ==
                              'CRUCE_VALOR_ANTICIPO'
                          "
                          dense
                        ></v-text-field>
                      </td>
                      <td class="text-center"></td>
                      <td class="text-center">
                        <span
                          v-if="
                            ['PLACE_TO_PAY_DC', 'PLACE_TO_PAY_CC'].includes(
                              payment.payment_method_type_id
                            ) && payment.payment_status == 'RECEIVED'
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="success"
                                class="mx-2"
                                @click="openQutation"
                              >
                                mdi-refresh
                              </v-icon>
                            </template>
                            <span>Verificar pago PlaceToPay</span>
                          </v-tooltip>
                        </span>
                        <b
                          v-if="
                            ![
                              'PAYMENT_AUTHORIZED',
                              'AUTHORIZED',
                              'RECEIVED',
                            ].includes(payment.payment_status) &&
                            ['PLACE_TO_PAY_DC', 'PLACE_TO_PAY_CC'].includes(
                              payment.payment_method_type_id
                            )
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                width="50px"
                                height="50px"
                                icon
                                fab
                                v-bind="attrs"
                                v-on="on"
                                class="mx-2"
                                @click="getPlaceToPayPaymentLink(payment)"
                              >
                                <v-img
                                  src="../../assets/images/placetopay-logo.png"
                                  width="50px"
                                  height="50px"
                                />
                              </v-btn>
                            </template>
                            <span>Generar link de pago de PlaceToPay</span>
                          </v-tooltip>
                        </b>
                        <span
                          v-if="
                            $route.params.work_effort_id == undefined &&
                            payment.payment_method_type_id != '' &&
                            payment.payment_method_type_id != null &&
                            dataQuotation.payments[x].payment_status !=
                              'AUTHORIZED' &&
                            [
                              'CREDIT_CARD',
                              'CREDIT_CARD_DIF_INT',
                              'CREDIT_CARD_DIF',
                              'DEBIT_CARD',
                            ].includes(payment.payment_method_type_id)
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                :disabled="dataQuotation.estado == 'FACTURADO'"
                                color="primary"
                                class="mx-2"
                                @click="confirmCrediCardPayment(payment)"
                                >mdi-check-all
                              </v-icon>
                            </template>
                            <span>Confirmar pago de tarjeta</span>
                          </v-tooltip>
                        </span>
                        <span
                          v-if="
                            ($route.params.work_effort_id == undefined &&
                              !['PLACE_TO_PAY_DC', 'PLACE_TO_PAY_CC'].includes(
                                payment.payment_method_type_id
                              )) ||
                            (!['PAYMENT_AUTHORIZED', 'AUTHORIZED'].includes(
                              payment.payment_status
                            ) &&
                              !['PLACE_TO_PAY_DC', 'PLACE_TO_PAY_CC'].includes(
                                payment.payment_method_type_id
                              ) &&
                              payment.payment_method_type_id != '' &&
                              payment.payment_method_type_id != null)
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                :disabled="dataQuotation.estado == 'FACTURADO'"
                                color="success"
                                class="mx-2"
                                @click="storeNewPayment(payment)"
                                >mdi-content-save
                              </v-icon>
                            </template>
                            <span>Guardar pago</span>
                          </v-tooltip>
                        </span>
                        <span
                          v-if="
                            ['PAYMENT_AUTHORIZED', 'AUTHORIZED'].includes(
                              payment.payment_status
                            )
                          "
                          class="green--text"
                        >
                          <b>PAGO CONFIRMADO</b>
                        </span>
                        <span
                          v-if="
                            confTransfPayPermission &&
                            $route.params.work_effort_id != undefined &&
                            (payment.payment_status == 'RECEIVED' ||
                              payment.payment_status == 'NOT-AUTHORIZED')
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                :disabled="dataQuotation.estado == 'FACTURADO'"
                                color="success"
                                class="mx-4"
                                @click="
                                  abrirDialogoConfirmarTransferencia(payment, x)
                                "
                                >mdi-check-all</v-icon
                              >
                            </template>
                            <span>Confirmar pago</span>
                          </v-tooltip>
                        </span>
                        <span
                          v-if="
                            dataQuotation.payments[x].payment_status !=
                              'AUTHORIZED' &&
                            $route.params.work_effort_id == undefined
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                :disabled="dataQuotation.estado == 'FACTURADO'"
                                color="red"
                                @click="deletePaymentQuote(payment, x)"
                              >
                                mdi-delete-forever
                              </v-icon>
                            </template>
                            <span>Eliminar pago</span>
                          </v-tooltip>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="3">
              <v-card>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <td colspan="2" class="td-head-custom text-center">
                          <v-icon color="white">mdi-cash-100</v-icon> Montos de
                          pago
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr>
                                                <td class="td-custom">Sub Total</td>
                                                <td>${{paymentAmounts.subTotal}}</td>
                                            </tr> -->
                      <tr>
                        <td class="td-custom">Sub Total IVA 0</td>
                        <td>
                          ${{
                            (
                              parseFloat(paymentAmounts.subTotalImp0) +
                              parseFloat(priceShipment)
                            ).toFixed(2)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="td-custom">Sub Total IVA 12%</td>
                        <td>
                          ${{
                            parseFloat(paymentAmounts.subTotalImp) +
                            parseFloat(paymentAmounts.totalImp)
                          }}
                        </td>
                      </tr>
                      <!-- <tr>
                                                <td class="td-custom">Total IVA	</td>
                                                <td>${{paymentAmounts.totalImp}}</td>
                                            </tr> -->
                      <tr>
                        <td class="td-custom">Costo envío</td>
                        <td>$ {{ parseFloat(priceShipment).toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <td class="td-custom custom-price">Total</td>
                        <td class="custom-price">
                          ${{ paymentAmounts.total }}
                          <span
                            :style="`height:17px;width:17px;border-radius: 60px;background:${quoteMarginColor};position: relative;top: 3px;right: 7px;float: left;`"
                          ></span>
                        </td>
                      </tr>
                      <tr>
                        <td class="td-custom custom-price">Total PVP</td>
                        <td class="custom-price">
                          ${{ paymentAmounts.totalPvP }}
                        </td>
                      </tr>
                      <tr>
                        <td class="td-custom custom-price">Total Ahorro</td>
                        <td class="custom-price">
                          ${{ paymentAmounts.totalSaving }}
                        </td>
                      </tr>
                      <tr>
                        <td class="td-custom">Efectivo</td>
                        <td>${{ paymentAmounts.cash }}</td>
                      </tr>
                      <tr>
                        <td class="td-custom">TC corriente</td>
                        <td>${{ paymentAmounts.currentTC }}</td>
                      </tr>
                      <tr>
                        <td class="td-custom">TC diferido</td>
                        <td>${{ paymentAmounts.deferredTD }}</td>
                      </tr>
                      <tr>
                        <td class="td-custom">Tarjeta débito</td>
                        <td>${{ paymentAmounts.debitCard }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td colspan="8" class="td-head-custom text-center">
                        <v-icon color="white">mdi-cash-100</v-icon> Pagos a
                        favor
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 0px" class="td-custom text-center">
                        ID
                      </td>
                      <td
                        style="padding: 0px; width: 250px"
                        class="td-custom text-center"
                      >
                        Forma de pago
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Monto
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Saldo
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Referencia
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Estado
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Aplicar
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(payment, x) in dataQuotation.paymentsInFavor"
                      :key="x"
                    >
                      <td class="text-center">{{ payment.payment_id }}</td>
                      <td class="text-center" style="width: 250px">
                        {{ payment.description }}
                      </td>
                      <td class="text-center">$ {{ payment.amount }}</td>
                      <td class="text-center">$ {{ payment.balance }}</td>
                      <td class="text-center">{{ payment.payment_ref_num }}</td>
                      <td class="text-center">{{ payment.status }}</td>
                      <td class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="success"
                              @click="applyManualPayment(payment)"
                              >mdi-check</v-icon
                            >
                          </template>
                          <span>Aplicar pago a favor</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="6">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td colspan="5" class="td-head-custom text-center">
                        <v-icon color="white">mdi-credit-card-plus</v-icon>
                        Crédito del cliente
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 0px" class="td-custom text-center">
                        Monto
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Usado
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Restante
                      </td>
                      <td style="padding: 0px" class="td-custom text-center">
                        Caduca
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item
        v-show="enabledPayments && $route.params.work_effort_id == undefined"
      >
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="4" class="mt-8">
              <v-autocomplete
                label="Seleccione un transportista para crear un envío express"
                v-model="expressShippingPartyId"
                :items="carriers"
                item-value="party_id"
                item-text="name"
                class="mt-0 input-quotation"
                style="height: 25px"
                dense
                @change="envioExpress"
                prepend-inner-icon="mdi-run-fast"
                clearable
              />
            </v-col>
            <v-col cols="12" md="2" class="mt-6">
              <v-text-field
                prepend-inner-icon="mdi-18px mdi-barcode-scan"
                v-model="barCodeItem"
                dense
                autofocus
                placeholder="Código de barras"
                ref="inputBarCode"
                @blur="$refs.inputBarCode.focus()"
                @input="searchBarCodeItem"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-simple-table dense class="mx-2">
          <template v-slot:default>
            <thead>
              <tr>
                <td colspan="11" class="td-head-custom text-center">
                  <v-icon color="white">mdi-cart-outline</v-icon> ITEMS DE LA
                  VENTA
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 3rem" class="td-custom">Sec</td>
                <td class="td-custom">Producto</td>
                <td class="td-custom" style="width: 500px">Descripción</td>
                <td class="td-custom">Marca</td>
                <td class="td-custom">Lote</td>
                <td class="td-custom">Expiración</td>
                <td class="td-custom">Cantidad</td>
                <td class="td-custom">Inventario local</td>
                <td class="td-custom">Código de barras</td>
                <td class="td-custom text-center">Verificado</td>
              </tr>
              <tr v-for="(item, x) in dataQuotation.items_order" :key="x">
                <td>{{ item.seqId }}</td>
                <td>{{ item.productId }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.marca }}</td>
                <td>{{ item.loteId }}</td>
                <td :style="`color: ${item.color}`">
                  {{ item.expirationDate }}
                </td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.stock }}</td>
                <td>{{ item.codigoBarra }}</td>
                <td class="text-center">
                  <v-icon :color="item.verificado ? 'success' : 'red'">
                    mdi-{{
                      item.verificado ? "check-all" : "close-circle-outline"
                    }}
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table dense class="mx-2 mt-10">
          <template v-slot:default>
            <thead>
              <tr>
                <td colspan="8" class="td-head-custom text-center">
                  <v-icon color="white">mdi-cart-outline</v-icon> INGRESO DE
                  RECETAS
                  <div class="float-md-right">
                    <v-btn
                      color="primary"
                      small
                      style="height: 21px"
                      @click="addRecipe"
                      :disabled="dataQuotation.estado == 'FACTURADO'"
                    >
                      <v-icon>mdi-plus</v-icon> Agregar receta
                    </v-btn>
                  </div>
                </td>
              </tr>
            </thead>
            <v-col>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  v-for="(recipe, x) in dataQuotation.recipes"
                  :key="x"
                >
                  <FullscreenViewCamera
                    v-model="recipe.picture"
                    :startOnMounted="false"
                    ref="cam"
                  />
                  <v-card class="mx-2 mt-10">
                    <div>
                      <v-btn
                        color="primary"
                        small
                        style="height: 21px"
                        @click="openCamera(x)"
                        :disabled="dataQuotation.estado == 'FACTURADO'"
                      >
                        <v-icon>mdi-image-filter</v-icon> Abrir Camara</v-btn
                      >
                      <v-btn
                        color="primary"
                        small
                        style="height: 21px"
                        @click="takePictureRecip(x)"
                        :disabled="dataQuotation.estado == 'FACTURADO'"
                      >
                        <v-icon>mdi-image-filter-center-focus</v-icon> Tomar
                        foto</v-btn
                      >
                      <v-btn
                        color="primary"
                        small
                        style="height: 21px"
                        :disabled="dataQuotation.estado == 'FACTURADO'"
                      >
                        <v-icon>mdi-cloud-upload</v-icon> Subir archivo</v-btn
                      >
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-simple-table>
        <v-col class="mx-1">
          <v-row>
            <v-col
              v-for="(recipe, x) in dataQuotation.contents"
              :key="x"
              cols="12"
              md="6"
            >
              <iframe
                :src="recipe.content_name"
                height="400px"
                width="600px"
              ></iframe>
            </v-col>
          </v-row>
        </v-col>
      </v-tab-item>
    </v-tabs-items>

    <!-- Dialog Cupones -->
    <v-dialog v-model="dialogCupones" max-width="400">
      <v-form ref="formCupon">
        <v-card color="white" solid>
          <v-toolbar flat height="30" dark color="blue"
            ><v-icon left>mdi-ticket-percent</v-icon>Ingresar cupones
            <v-divider></v-divider>
            <v-separator></v-separator>

            <v-btn small text @click="dialogCupones = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>

          <v-col cols="12" class="mt-1">
            <v-select
              class="py-0"
              label="Seleccione la promocion"
              v-model="codigoPromo"
              :items="codigosPromos"
              clearable
              item-text="promo_name"
              item-value="product_promo_code_id"
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              v-if="codigoPromo != null"
              class="py-0"
              label="Ingrese el código"
              v-model="codigoExterno"
              :rules="ruleRequired"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-text-field
              v-if="codigoPromo == null"
              class="py-0"
              label="Ingrese un cupón"
              v-model="cuponCotizacion"
              :rules="ruleCodigoCupon"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-btn color="green" small dark @click="guardarCupon"
              ><v-icon left>mdi-content-save</v-icon>Guardar</v-btn
            >
            <v-btn
              class="mx-4"
              color="blue"
              small
              dark
              text
              @click="dialogCupones = false"
              ><v-icon left>mdi-cancel</v-icon>Cancelar</v-btn
            >
          </v-col>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogoTransferencia" persistent width="500">
      <v-card>
        <v-toolbar flat color="green" dark height="45">
          <v-toolbar-title>Transferencia</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small icon @click="dialogoTransferencia = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-form ref="formConfirmarTransferencia">
          <v-row class="pa-2 ma-0">
            <v-col>
              <v-select
                :rules="ruleRequired"
                v-model="finAccountId"
                label="Cuenta bancaria"
                :items="dataRequestQuotation.banks"
                item-text="fin_account_name"
                item-value="fin_account_id"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="pa-2 ma-0">
            <v-col>
              <v-text-field
                :rules="ruleRequired"
                v-model="fechaTransfe"
                label="Fecha"
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="px-2 py-4 ma-0">
            <v-col>
              <v-btn small dark @click="confirmarTransferencia()" color="green"
                ><v-icon>mdi-content-save</v-icon>Confirmar</v-btn
              >
            </v-col>

            <v-spacer></v-spacer>
            <v-col>
              <v-btn
                @click="dialogoTransferencia = false"
                small
                text
                color="blue"
                ><v-icon>mdi-cancel</v-icon>Cancelar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>

    <iframe
      name="framePrint"
      style="width: 0px; height: 0px; visibility: hidden"
    ></iframe>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CaptureEmail from "../general/CaptureEmail";
import CapturePhone from "../general/CapturePhone";
import CaptureProducts from "../general/CaptureProducts";
import CaptureDirection from "../general/CaptureDirection";
import CapturePatient from "../general/CapturePatient";
import CaptureClient from "../general/CaptureClient";
import Map from "../general/Map";
import Vue from "vue";
import moment from "moment";
import FullscreenViewCamera from "easy-vue-camera";
import Geohash from "latlon-geohash";
import sha1 from "sha1";
import axios from "axios";

var intervalAditionlInfo = "";
var intervalInfoShipping = "";
var intervalBarCode = "";
var intervalCreditCard = "";

export default {
  name: "DetailQuotationComponent",
  components: {
    CapturePhone,
    CaptureDirection,
    CaptureEmail,
    CaptureProducts,
    CapturePatient,
    CaptureClient,
    Map,
    FullscreenViewCamera,
  },
  data: () => ({
    tab: null,
    readerSize: {
      width: 300,
      height: 200,
    },
    aspectRatio: {
      min: 1,
      max: 1,
    },
    readerTypes: ["ean_reader"],
    actionData: 1, //1=> Nuevo, 2 => Editar , 3 => Ver
    product: "",
    dataQuotation: {
      payments: [],
      prescribers: [],
    },
    dataRequestQuotation: {
      cotizacion: {
        partyNombres: "",
      },
    },
    inventoryLotsHeaders: [
      { text: "TIENDA", value: "store_name" },
      { text: "PRODUCTO", value: "product_name" },
      { text: "INVENTARIO ACTUAL", value: "inventory" },
      {
        text: "VER LOTES",
        align: "center",
        value: "data-table-expand",
        width: "100px",
      },
    ],
    expandedInventory: [],
    productsQuotation: [],
    directions: [],
    billingAddress: [],
    patients: [],
    dataTable: [],
    phones: [],
    emails: [],
    deadLines: [],
    lastBill: [],
    storeInventories: [],
    relatedPrescribers: [],
    pointShippment: [],
    arrContactMechGeoPint: [],
    clients: [],
    creditsCardsClient: [],
    carriers: [],
    retentions: [],
    inventoryItemSelected: {},
    payment: {},
    description: "",
    info1: "",
    info2: "",
    info3: "",
    instructions: null,
    sendAfter: null,
    deliverBefore: null,
    guia: null,
    statusQuote: null,
    subTotalLastBill: 0,
    totalLastBill: 0,
    itemQuantity: 1,
    quoteMarginColor: "#fff",
    phoneSelected: "",
    prescriberSelected: "",
    patientSelected: "",
    directionSelected: "",
    emailSelected: "",
    wayToPaySelected: "",
    deadlineSelected: "",
    shippingMethodSelected: "",
    establishmentSelected: "",
    priceShipment: 0,
    loadingTableProducts: false,
    loadingEstablishmentSelected: false,
    loadingWayToPaySelected: false,
    loadingShippingMethodSelected: false,
    loadingPhoneSelected: false,
    loadingEmailSelected: false,
    loadingPlace: false,
    loadingDeadlineSelected: false,
    loadingPrescriptorSelected: false,
    loadingPatientSelected: false,
    dialogDataQuotation: false,
    dialogStoreInventory: false,
    dialogPrescriber: false,
    psychotropic: false,
    productControlled: false,
    dialogShoppingHistory: false,
    dialogCancelQuotation: false,
    dialogCreditCardPaymentData: false,
    availableInventory: false,
    haveCredit: false,
    dialogCreditCard: false,
    disabledShipping: false,
    dialogBillingAddress: false,
    searchPrescribers: null,
    attnName: null,
    tlfAttnName: null,
    orderId: null,
    barCodeItem: null,
    creditCardSelected: null,
    dialogClient: null,
    shippingType: "",
    reasonCancel: null,
    descriptionCancel: null,
    printer: null,
    labelPrinter: null,
    labelIpPrinter: null,
    ipPrinter: null,
    portPrinter: null,
    expressShippingPartyId: null,
    dataStoreCreditCard: {
      card_number: null,
      expire_date: null,
      cvv: null,
      first_name_on_card: null,
      last_name_on_card: null,
      enum_id: null,
    },
    defaultDataStoreCreditCard: {
      card_number: null,
      expire_date: null,
      cvv: null,
      first_name_on_card: null,
      last_name_on_card: null,
      enum_id: null,
    },
    creditCardPaymentData: {
      card_type: null,
      first_name_on_card: null,
      last_name_on_card: null,
      card_number: null,
      cvv: null,
      expire_date: null,
    },
    creditCardPaymentDataDefault: {
      card_type: null,
      first_name_on_card: null,
      last_name_on_card: null,
      card_number: null,
      cvv: null,
      expire_date: null,
    },
    dialogosInformation: {
      addPhoneClient: false,
      addDirection: false,
      addEmail: false,
      addProducts: false,
      patient: false,
      maxWidthDialog: "",
      invoceInformation: false,
      statusInformation: false,
    },
    defaultDialogInformation: {
      addPhoneClient: false,
      addDirection: false,
      addEmail: false,
      addProducts: false,
      patient: false,
      maxWidthDialog: "",
      invoceInformation: false,
      statusInformation: false,
    },
    paymentAmounts: {
      subTotal: 0.0,
      subTotalImp0: 0.0,
      subTotalImp: 0.0,
      totalImp: 0.0,
      total: 0.0,
      totalPvP: 0.0,
      totalSaving: 0.0,
      cash: 0.0,
      currentTC: 0.0,
      deferredTD: 0.0,
      debitCard: 0.0,
    },
    dialogInfoProd: false,
    datosPromo: {},
    infoProd: {},
    mostrarPromo: false,
    ruleRequired: [(v) => !!v || ""],
    ruleCreditCard: [(v) => !!v || "El campo es obligatorio"],
    headersPrescribers: [
      { text: "ID", align: "start", value: "partyid" },
      { text: "Prescriptor", align: "start", value: "nombres" },
      { text: "Seleccionar", align: "start", value: "select" },
    ],
    shippingTypes: [
      { id: "NO_SHIPPING", name: "Sin envío" },
      { id: "SHIPPING", name: "Con envío" },
    ],
    dialogCupones: false,
    headerCupones: [
      {
        text: "Codigo ",
        align: "start",
        value: "codigo_externo",
      },
      { text: "Promocion", align: "start", value: "promo_name" },
      { text: "Opciones", align: "start", value: "opciones_cupon" },
    ],
    cupones: [],
    codigoPromo: null,
    cuponCotizacion: "",
    codigoExterno: "",
    codigosPromos: [],

    dialogoTransferencia: false,
    finAccountId: "",
    pagoConfirmar: null,
    fechaTransfe: "",
  }),
  watch: {
    wayToPaySelected: function (val) {
      this.asingDeadLine();
    },

    dataRequestQuotation: function (val) {
      this.asingQuoteData();
    },

    shippingType: function (val) {


      //QUITO CITIMED
      /*if (this.establishmentSelected == "10000") {
        let printTicket = "";

        if (val == "SHIPPING") {
          printTicket = this.dataQuotation.printers.find(
            (e) => e.rol == "IMPRESORA_TRASERA"
          );
        } else {
          printTicket = this.dataQuotation.printers.find(
            (e) => e.rol == "IMPRESORA_DELANTERA"
          );
        }

        if (typeof printTicket != "undefined") {
          this.printer = printTicket.printer;
          this.ipPrinter = printTicket.ip;
          this.portPrinter = printTicket.printer_port;
        }

        let labelP = this.dataQuotation.printers.find(
          (e) => e.rol == "IMPRESORA_TICKET_TRASERA"
        );

        if (typeof labelP != "undefined") {
          this.labelPrinter = labelP.printer;
          this.labelIpPrinter = labelP.ip;
        }
      }*/

      let printTicket = "";

        if (val == "SHIPPING") {
          printTicket = this.dataQuotation.printers.find(
            (e) => e.rol == "IMPRESORA_TRASERA"
          );
        } else {
          printTicket = this.dataQuotation.printers.find(
            (e) => e.rol == "IMPRESORA_DELANTERA"
          );
        }

        if (typeof printTicket != "undefined") {
          this.printer = printTicket.printer;
          this.ipPrinter = printTicket.ip;
          this.portPrinter = printTicket.printer_port;
        }

        let labelP = this.dataQuotation.printers.find(
          (e) => e.rol == "IMPRESORA_TICKET_TRASERA"
        );

        if (typeof labelP != "undefined") {
          this.labelPrinter = labelP.printer;
          this.labelIpPrinter = labelP.ip;
        }

      //GUAYAQUIL (WTC)
      /*if (this.establishmentSelected == "10010") {
        let printTicket = "";

        if (val == "SHIPPING") {
          printTicket = this.dataQuotation.printers.find(
            (e) => e.rol == "IMPRESORA_TRASERA"
          );
        } else {
          printTicket = this.dataQuotation.printers.find(
            (e) => e.rol == "IMPRESORA_DELANTERA"
          );
        }

        if (typeof printTicket != "undefined") {
          this.printer = printTicket.printer;
          this.ipPrinter = printTicket.ip;
          this.portPrinter = printTicket.printer_port;
        }

        let labelP = this.dataQuotation.printers.find(
          (e) => e.rol == "IMPRESORA_TICKET_TRASERA"
        );

        if (typeof labelP != "undefined") {
          this.labelPrinter = labelP.printer;
          this.labelIpPrinter = labelP.ip;
        }
      }*/
    },
  },
  computed: {
    ...mapState("pos", [
      "ubication",
      "wayToPays",
      "prescribers",
      "plazosPago",
      "ubications",
      "productStore",
      "shippingMethods",
      "reasonsCancel",
    ]),

    ...mapState("access", ["idActionsRol"]),

    ...mapState("pos", ["quoteId", "partyIdClient", "productStore"]),

    ...mapState("master", [
      "paramAlertQuestion",
      "objGeoCoding",
      "user",
      "tenantId",
      "overlay",
    ]),

    dataDirection() {
      let obj = this.directions.find(
        (e) => e.direccionId == this.directionSelected
      );
      return typeof obj != "undefined" ? obj : {};
    },

    whitShipping() {
      return this.shippingType == "SHIPPING";
    },

    validateGeneralDataAndShipping() {
      let valid = { success: true, message: "" };

      let arrGeneraldata = [
        {
          name: "Debe ingresar el paciente",
          value: this.patientSelected,
        },
        {
          name: "Debe seleccionar al cliente",
          value: this.dataQuotation.partyId,
        },
        {
          name: "Debe ingresar el teléfono del cliente",
          value: this.phoneSelected,
        },
        {
          name: "Debe ingresar el método de pago",
          value: this.wayToPaySelected,
        },
        {
          name: "Debe ingresar el prescriptor del paciente",
          value: this.prescriberSelected,
        },
        {
          name: "Debe ingresar el plazo de pago del cliente",
          value: this.deadlineSelected,
        },
        {
          name: "Debe ingresar la dirección del cliente",
          value: this.directionSelected,
        },
        {
          name: "Debe ingresar la dirección de facturacion cliente",
          value: this.dataQuotation.direccionId,
        },
        {
          name: "Debe agregar al menos un árticulo a la cotización",
          value: this.productsQuotation.length ? true : "",
        },
      ];

      if (this.shippingType == "SHIPPING") {
        arrGeneraldata.push(
          {
            name: "Debe ingresar el método de envío",
            value: this.shippingMethodSelected,
          },
          {
            name: "Debe ingresar la fecha y hora de entrega",
            value: this.deliverBefore,
          },
          {
            name: "Debe ingresar la fecha y hora de envío",
            value: this.sendAfter,
          },
          {
            name: "Debe ingresar el nombre y teléfono de la persona que atenderá en la dirección de entrega",
            value: this.attnName,
          }
        );
      }

      for (let i = 0; i < arrGeneraldata.length; i++) {
        if (arrGeneraldata[i].value == "" || arrGeneraldata[i].value == null) {
          valid = {
            success: false,
            message: arrGeneraldata[i].name,
          };
          break;
        }
      }

      return valid;
    },

    filterWayToPays() {

      return this.wayToPays.filter((obj) => {
          return !this.haveCredit
            ? obj.payment_method_type_id != "CREDITO"
            : obj;
        });
      
    },

    paymentsStep() {
      return (
        (this.tab == 0 || this.tab == 1) &&
        this.dataQuotation.estado == "CREADA"
      );
    },

    enabledPayments() {
      const { estado } = this.dataQuotation;

      return (
        (estado == "GANADA" ||
          estado == "CONFIRMADO" ||
          estado != "FACTURADO" ||
          estado == "PEDIDO_RESERVADO") &&
        this.orderId != null
      );
    },

    enableDispatch() {
      if (this.dataQuotation.payments.length) {
        let maxAmount = (obj) => (obj.max_amount == null ? 0 : obj.max_amount);
        let reducer = (previousAmount, currentAmount) =>
          parseFloat(previousAmount) + parseFloat(currentAmount);
        let verificado =
          this.dataQuotation.items_order.length ==
          this.dataQuotation.items_order.filter((obj) => obj.verificado == true)
            .length;
        let validData = this.validateGeneralDataAndShipping;

        /*console.log(
          parseFloat(
            this.dataQuotation.payments.map(maxAmount).reduce(reducer)
          ).toFixed(2) >= parseFloat(this.paymentAmounts.total),
          this.tab == 3,
          verificado,
          !this.whitShipping,
          validData,
          parseFloat(
            this.dataQuotation.payments.map(maxAmount).reduce(reducer)
          ),
          parseFloat(this.paymentAmounts.total)
        );*/

        if (!validData.success) {
          Vue.swal({
            html: validData.message,
            icon: "warning",
            ...this.paramAlertQuestion,
          });
        }

        return (
          parseFloat(
            this.dataQuotation.payments.map(maxAmount).reduce(reducer)
          ).toFixed(2) >= parseFloat(this.paymentAmounts.total) &&
          this.tab == 3 &&
          verificado &&
          (!this.whitShipping || this.dataQuotation.existenPsico == "SI") &&
          validData.success &&
          this.dataQuotation.estado != "FACTURADO"
        );
      } else {
        return false;
      }
    },

    refreshQuote() {
      return this.dataQuotation.estado == "SOLICITANDO_APROBACION";
    },

    btnEdiarGuia() {
      const { estado } = this.dataQuotation;
      return estado != "QUO_CANCELED" && estado != "CANCELADA";
    },

    btnCancelQuote() {
      const { estado } = this.dataQuotation;
      return (
        estado != "QUO_CANCELED" &&
        estado != "FACTURADO" &&
        estado != "CANCELADA" &&
        estado != "SOLICITANDO_APROBACION"
      );
    },

    creditCard() {
      let obj = this.dataQuotation.payments.find(
        (e) =>
          e.payment_method_type_id == "CREDIT_CARD" ||
          e.payment_method_type_id == "CREDIT_CARD_DIF" ||
          e.payment_method_type_id == "CREDIT_CARD_DIF_INT" ||
          e.payment_method_type_id == "DEBIT_CARD"
      );
      return typeof obj != "undefined";
    },

    returnQuotation() {
      const { estado } = this.dataQuotation;
      return (
        estado != "FACTURADO" && estado != "CANCELADA" && this.orderId != null
      );
    },

    dateQuotation() {
      if (typeof this.dataRequestQuotation.cotizacion != "undefined")
        return moment(this.dataRequestQuotation.cotizacion.issueDate).format(
          "DD/MM/YYYY"
        );
    },

    statusQuotation() {
      if (typeof this.dataRequestQuotation.cotizacion != "undefined")
        return this.dataRequestQuotation.cotizacion.estado;
    },

    confTransfPayPermission() {
      return this.idActionsRol.find((e) => e === "CONF_PAGO_CLIENTE");
    },

    tdcData() {
      return this.idActionsRol.find((e) => e === "VER_DATOS_TDC");
    },

    recipColumn() {
      return this.productsQuotation.filter((e) => e.psicotropic == "SI").length;
    },

    getDatosPromoTienda() {
      for (let i = 0; i < this.datosPromo.length; i++) {}
    },

    ruleCodigoCupon() {
      if (this.codigoPromo == null) {
        if (this.cuponCotizacion == "" || this.cuponCotizacion == null) {
          return [false || "Ingrese el cupon"];
        }
      }
    },
  },
  methods: {
    ...mapMutations("pos", [
      "setPartyIdClient",
      "setShippingMethods",
      "setUbication",
      "setWayToPays",
      "setQuoteId",
    ]),

    ...mapMutations("master", ["setUrl", "setOverlay", "setDrawer"]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    ...mapActions("pos", ["dataPrimaryPos", "dataShippmentMethods"]),

    dialogInformation(type) {
      switch (type) {
        case "phone":
          this.dialogosInformation.addPhoneClient = true;
          this.defaultDialogInformation.maxWidthDialog = "400px";
          break;
        case "direction":
          this.dialogosInformation.addDirection = true;
          this.defaultDialogInformation.maxWidthDialog = "700px";
          break;
        case "products":
          this.searchProduct();
          break;
        case "patient":
          this.dialogosInformation.patient = true;
          this.defaultDialogInformation.maxWidthDialog = "500px";
          break;
        case "client":
          this.dialogosInformation.client = true;
          this.defaultDialogInformation.maxWidthDialog = "699px";
          break;
        case "invoceInformation":
          this.dialogosInformation.invoceInformation = true;
          this.defaultDialogInformation.maxWidthDialog = "700px";
          this.getClients();
          break;
        case "statusInformation":
          this.getQuoteStatus();
          this.dialogosInformation.statusInformation = true;
          this.defaultDialogInformation.maxWidthDialog = "700px";
          break;
        default:
          this.dialogosInformation.addEmail = true;
          this.defaultDialogInformation.maxWidthDialog = "400px";
          break;
      }
      this.dialogDataQuotation = true;
    },

    closeDialogInformation(val) {
      this.dialogDataQuotation = val;
      Object.assign(this.dialogosInformation, this.defaultDialogInformation);
    },

    searchProduct() {
      this.loadingTableProducts = true;
      this.dataTable = [];
      this.setUrl("productos");
      this.requestApi({
        method: "GET",
        data: {
          producto: this.product,
          bodegaId: this.establishmentSelected,
          productStoreId: this.establishmentSelected,
          partyId: this.dataRequestQuotation.cotizacion.partyId,
        },
      }).then((res) => {
        // console.log(res.data)
        this.dataTable = res.data._embedded.productos;
        this.loadingTableProducts = false;
      });

      this.defaultDialogInformation.maxWidthDialog = "1200px";
      this.dialogosInformation.addProducts = true;
    },

    updateProducts(products) {
      this.dataTable = products._embedded.productos.map((obj) => {
        return Object.assign(obj, { quantity: 1 });
      });
    },

    updateLoadingTable(val) {
      this.loadingTableProducts = val;
    },

    addPayment() {
      this.dataQuotation.payments.push({
        manual_ref_num: null,
        max_amount: null,
        order_payment_preference_id: null,
        payment_status: null,
        payment_type: null,
        payment_method_id: null,
      });
    },

    asingDeadLine() {
      this.deadLines = this.plazosPago.filter(
        (e) => e.payment_method_type_id == this.wayToPaySelected
      );
      //console.log(this.plazosPago, this.wayToPaySelected, this.deadLines, this.dataQuotation.plazoId)
      if (this.dataQuotation.plazoId == "") {
        this.deadlineSelected = this.dataQuotation.plazoId;
        this.changeDeadlineSelected();
      }
    },

    asingQuoteData() {
      this.productsQuotation = [];
      this.phones = [];
      this.emails = [];
      this.dataQuotation = this.dataRequestQuotation.cotizacion;
      this.statusQuote = this.dataQuotation.estado;
      this.haveCredit = this.dataQuotation.poseeCredito;
      this.orderId = this.dataQuotation.orderId;
      this.retentions = this.dataRequestQuotation.retentions;

      this.setQuoteId(this.dataQuotation.quoteId); // Setea el quoteId de turno

      //ASIGNA PACIENTES
      this.patients = this.dataRequestQuotation.patients;
      this.patients.unshift({
        name: this.dataQuotation.partyNombres,
        party_id: this.dataQuotation.partyId,
      });

      if (
        this.dataQuotation.pacienteId != "" &&
        this.dataQuotation.pacienteId != null
      ) {
        this.patientSelected = this.dataQuotation.pacienteId;
      } else {
        this.patientSelected = this.dataQuotation.partyId;
      }

      //ASIGNA TELEFONOS
      this.dataRequestQuotation.telefsCliente
        .filter((obj) => obj.contactmechid != null && obj.contactmechid != "")
        .map((obj) => {
          this.phones.push({
            cmId2: obj.contactmechid,
            cmId: obj.contactmechid,
            phone: obj.contactnumber,
          });
        });

      //SELECCIONA EL TLF DE LA COTIZACIÓN
      if (
        this.dataQuotation.telefonoId != null &&
        this.dataQuotation.telefonoId != ""
      ) {
        let selectedPhone = this.phones.find(
          (e) => e.cmId2 == this.dataQuotation.telefonoId
        );

        if (typeof selectedPhone != "undefined")
          this.phoneSelected = this.dataQuotation.telefonoId;
      }

      //ASIGNA PRESCRIPTOR
      if (this.dataQuotation.referente1Id != null)
        this.prescriberSelected = this.dataQuotation.referente1Id;

      //ASIGNA PRESCRIPTORES RELACIONADOS
      this.relatedPrescribers = this.dataQuotation.prescribers;

      //ASIGNA OTRAS DIRECCIONES
      this.dataRequestQuotation.dirsCliente.map((e) => {
        if (e.contactmechid != null && e.contactmechid != "") {
          let nameDirection = `${
            e.calleprincipal == null ? "" : e.calleprincipal
          } ${e.callesecundaria == null ? "" : e.callesecundaria} ${
            e.provincia == null ? "" : e.provincia
          } ${e.ciudad == null ? "" : e.ciudad} ${
            e.observaciones == null ? "" : e.observaciones
          }`;

          let dir = {};

          if (e.geo_point_id == null) {
            console.log(e.geo_point_id == null, nameDirection);

            this.objGeoCoding.provider
              .search({ query: nameDirection })
              .then((res) => {
                console.log(res);
                if (typeof res[0] != "undefined") {
                  this.setUrl(
                    `cotizacion/${this.dataQuotation.quoteId}/crea-geopoint`
                  );
                  this.requestApi({
                    method: "PATCH",
                    data: {
                      contactmechid: e.contactmechid,
                      latLng: [res[0].y, res[0].x],
                      label: res[0].label,
                    },
                  });

                  console.log("No tiene geo point");
                  console.log(e);

                  if (typeof e.contactmechid != "undefined") {
                    this.directions.push({
                      direccionId: e.contactmechid,
                      name: res[0].label,
                      attnName: e.attn_name,
                      tlfAttnName: e.tlf_attn_name,
                    });
                  }
                }
              });
          } else {
            if (typeof e.contactmechid != "undefined") {
              this.directions.push({
                direccionId: e.contactmechid,
                name: nameDirection,
                attnName: e.attn_name,
                tlfAttnName: e.tlf_attn_name,
              });
            }
          }
        }
      });

      //ASIGNA DIRECCIÓN DE LA COTIZACIÓN
      this.directionSelected =
        this.dataQuotation.direccionEnvioId != null &&
        this.dataQuotation.direccionEnvioId != ""
          ? this.dataQuotation.direccionEnvioId
          : this.dataQuotation.direccionDefaultId;

      //ASIGNA DIREECIONES DE FACTURACION
      this.dataRequestQuotation.billingAddress.forEach((obj) => {
        let nameDirection = `${
          obj.calleprincipal == null ? "" : obj.calleprincipal
        } ${obj.callesecundaria == null ? "" : obj.callesecundaria} ${
          obj.provincia == null ? "" : obj.provincia
        } ${obj.ciudad == null ? "" : obj.ciudad} ${
          obj.observaciones == null ? "" : obj.observaciones
        }`;

        this.billingAddress.push({
          direccionId: obj.contactmechid,
          name: nameDirection,
        });
      });

      //ASIGNA OTROS EMAILS
      this.dataRequestQuotation.emailsCliente.map((obj) => {
        obj.contactmechid != null &&
          obj.contactmechid != "" &&
          this.emails.push({
            emailId: obj.contactmechid,
            email: obj.email,
          });
      });

      //SELECCIONA EL EMAIL DE LA COTIZACIÓN
      if (this.dataQuotation.emailId != null)
        this.emailSelected = this.dataQuotation.emailId;

      //ASIGNA FORMA DE PAGO Y PLAZO
      if (this.dataQuotation.paymentMethodTypeId != null) {
        this.wayToPaySelected = this.dataQuotation.paymentMethodTypeId;
        this.asingDeadLine();
        this.deadlineSelected = this.dataQuotation.plazoId;
      }

      //ASIGNA METODOS DE ENVIO
      this.setShippingMethods(this.dataRequestQuotation.metodosEnvio);

      //ASIGNA METODO DE ENVÍO DE LA COTIZACIÓN
      if (this.dataQuotation.shipmentMethodTypeId != null) {
        this.shippingMethodSelected = this.dataQuotation.shipmentMethodTypeId;
        this.shippingType =
          this.shippingMethodSelected != "NO_SHIPPING"
            ? "SHIPPING"
            : "NO_SHIPPING";
      } else {
        this.shippingType = "NO_SHIPPING";
      }

      //ASIGNA DESCRIPTIÓN
      this.description = this.dataQuotation.description;

      //ASIGNA PSICOTROPICOS
      this.psychotropic = this.dataQuotation.existenPsico == "SI";

      //ASIGNA CONTROLADOS
      this.productControlled = this.dataQuotation.existenControlados == "SI";

      //ASIGNA PRODUCTOS
      if (
        typeof this.dataQuotation.items != "undefined" &&
        this.dataQuotation.items.length > 0
      ) {
        for (let item of this.dataQuotation.items) {
          this.productsQuotation.push({
            seqId: item.itemSeqId,
            quantity: parseInt(item.cantidad),
            sequence: item.secuencia,
            description: item.descripcion,
            product: item.productId,
            brand: item.marca,
            comments: item.comments,
            controlled: item.controlado,
            psicotropic: item.psicotropico,
            pctImp: item.pctImpuesto,
            total: item.total + (item.total * item.pctImpuesto) / 100,
            stock: item.disponible,
            recip: item.codigo_receta,
            pQuoted: item.precio,
            isPromo: item.is_promo,
            discount:
              item.valor_descuento == "" || item.valor_descuento == null
                ? 0
                : item.valor_descuento,
            color: "#fff",
            acuerdoId: item.acuerdoId,
            codigo_barra: item.codigo_barra,
            verificado: false,
          });
        }
      }

      //ASIGNA EL PRODUCT_STORE_ID DE LA COTIZACIÓN
      this.setUbication(this.dataRequestQuotation.cotizacion.productStoreId);

      this.establishmentSelected =
        this.dataRequestQuotation.cotizacion.productStoreId;

      //ASIGNA PRECIO DEL ENVIO
      this.priceShipment =
        this.shippingType == "NO_SHIPPING"
          ? 0
          : this.dataRequestQuotation.cotizacion.valorEnvio != null
          ? this.dataRequestQuotation.cotizacion.valorEnvio
          : 0;

      this.setPartyIdClient(this.dataQuotation.partyId);

      //ASINGAR ULTIMA FACTURA
      if (this.dataRequestQuotation.invoices.length) {
        this.lastBill = this.dataRequestQuotation.invoices.filter((obj) => {
          return (
            this.dataRequestQuotation.invoices[0].invoice_id == obj.invoice_id
          );
        });

        let st = (this.subTotalLastBill = this.lastBill.map((obj) => {
          if (obj.invoice_item_type_id != "ITM_SALES_TAX")
            return parseFloat(obj.amount);
          else return 0;
        }));

        let imp = (this.subTotalLastBill = this.lastBill.map((obj) => {
          if (obj.invoice_item_type_id == "ITM_SALES_TAX")
            return parseFloat(obj.amount);
          else return 0;
        }));

        st = st.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        imp = imp.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        this.subTotalLastBill = st.toFixed(2);
        let tt = st + imp;
        this.totalLastBill = tt.toFixed(2);
        this.lastBill = this.lastBill.filter((obj) => {
          if (obj.invoice_item_type_id != "ITM_SALES_TAX") return obj;
        });
      }

      //ASIGNA INFO ADICIONAL
      this.info1 = this.dataQuotation.info1;
      this.info2 = this.dataQuotation.info2;
      this.info3 = this.dataQuotation.info3;
      this.description = this.dataQuotation.description;
      this.instructions = this.dataQuotation.instrucciones;
      this.guia = this.dataQuotation.guia;
      this.attnName =
        this.dataQuotation.attn_name == null ||
        this.dataQuotation.attn_name == ""
          ? this.dataQuotation.partyNombres
          : this.dataQuotation.attn_name;

      if (
        this.dataQuotation.tlf_attn_name == null ||
        this.dataQuotation.tlf_attn_name == ""
      ) {
        let ps = this.phones.find((e) => e.cmId2 == this.phoneSelected);

        if (typeof ps != "undefined") {
          this.tlfAttnName = ps.phone;
        } else {
          this.tlfAttnName = "";
        }
      } else {
        this.tlfAttnName = this.dataQuotation.tlf_attn_name;
      }

      if (this.dataQuotation.entrega_desde != null) {
        this.sendAfter = moment(this.dataQuotation.entrega_desde).format(
          "YYYY-MM-DDTHH:mm"
        );
      } else {
        this.sendAfter = moment().add(1, "hour").format("YYYY-MM-DDTHH:mm");
      }

      if (this.dataQuotation.entrega_hasta != null) {
        this.deliverBefore = moment(this.dataQuotation.entrega_hasta).format(
          "YYYY-MM-DDTHH:mm"
        );
      } else {
        this.deliverBefore = moment().add(2, "hour").format("YYYY-MM-DDTHH:mm");
      }

      //ASIGNA LAS PERSONAS HABILITADA PARA EL ENVÍO EXPRESS
      this.carriers = this.dataRequestQuotation.carriers;

      //ASIGNA QUIEN HIZO ENVIO EXPRESS
      this.expressShippingPartyId = this.dataQuotation.partyIdEnvExpress;

      this.updateTablePrices();
    },

    addProductSelected(product) {
      const {
        productId,
        quantity,
        codImpuesto,
        costoProm,
        internalName,
        fechaCosto,
        pctImpuesto,
        precioAcuerdo,
        precioDef,
        precioPvp,
        brandName,
        acuerdoId,
        codigo_barra,
      } = product[0];

      this.setUrl("cotizacion-item");
      this.requestApi({
        method: "POST",
        data: {
          quoteId: this.dataQuotation.quoteId,
          productStoreId: this.establishmentSelected,
          productId,
          quantity,
          codImpuesto,
          costoProm,
          internalName,
          fechaCosto,
          pctImpuesto,
          precioAcuerdo,
          precioDef,
          precioPvp,
          brandName,
          acuerdoId,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.msg,
          },
        });

        let data = {
          description: res.data.itemDescription,
          seqId: res.data.quoteItemSeqId,
          quantity: res.data.quantity,
          sequence: res.data.secuencia,
          product: productId,
          brand: res.data.marca,
          controlled: res.data.prodControlado,
          psicotropic: res.data.psicotropico,
          pctImp: res.data.pctImpuesto,
          total: res.data.quantity * res.data.quoteUnitPrice,
          stock: res.data.disponible,
          pQuoted: res.data.quoteUnitPrice,
          color: "#fff",
          precioPvp: res.data.precioPvp,
          costoTotal: res.data.costo,
          itemSeqId: res.data.quoteItemSeqId,
          precioDef: parseFloat(res.data.precioEfe),
          precioTcc: res.data.precioTcc,
          precioTcd: res.data.precioTcd,
          precioTd: res.data.precioTd,
          discount: 0,
          isPromo: res.data.is_promo,
          acuerdoId,
          codigo_barra,
          verificado: false,
        };

        this.productsQuotation.push(data);
        this.dataQuotation.items.push(data);
        //this.$emit('editTableproducts',{edit:true, quote_id:this.dataQuotation.quoteId})
        //this.updateTablePrices()
        this.openQutation();
      });
    },

    deleteProductSelected(product) {
      this.setUrl("cotizacion-item");
      this.requestApi({
        method: "DELETE",
        data: {
          quoteId: this.dataQuotation.quoteId,
          seqId: product.seqId,
        },
      })
        .then((res) => {
          let obj = this.productsQuotation.find(
            (e) => e.seqId === product.seqId
          );
          let index = this.productsQuotation.indexOf(obj);
          this.productsQuotation.splice(index, 1);
          this.productsQuotation.forEach((obj, i) => {
            obj.seqId = String(i + 1).padStart(5, 0);
          });
          this.openQutation();
          this.updateTablePrices();
          let obj2 = this.dataQuotation.items.find(
            (e) => e.seqId == product.seqId
          );
          let index2 = this.dataQuotation.items.indexOf(obj2);
          this.dataQuotation.items.splice(index2, 1);
          this.dataQuotation.items.forEach((obj, i) => {
            obj.seqId = String(i + 1).padStart(5, 0);
          });

          this.alertNotification({
            param: {
              html: res.data.detail,
            },
          });
        })
        .then(() => {});
    },

    changeWayToPay() {
      this.loadingWayToPaySelected = true;
      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/forma-pago`);
      this.requestApi({
        method: "PATCH",
        data: {
          productStoreId: this.establishmentSelected,
          formaPagoId: this.wayToPaySelected,
          partyId: this.dataQuotation.partyId,
          totalQ: this.paymentAmounts.total,
        },
      })
        .then((res) => {
          for (let item of res.data.items) {
            let obj = this.productsQuotation.find((e) => e.seqId == item.seqId);
            if (typeof obj != "undefined") {
              let index = this.productsQuotation.indexOf(obj);
              this.productsQuotation[index].total =
                item.newUnitPrice * obj.quantity;
              this.productsQuotation[index].pQuoted = item.newUnitPrice;
            }
          }

          //console.log(this.deadLines);

          this.deadlineSelected = "";
          this.loadingWayToPaySelected = false;

          if (this.deadLines.length == 1) {
            this.deadlineSelected = this.deadLines[0].plazo_id;
            this.changeDeadlineSelected();
          }

          this.openQutation();
        })
        .then(() => {});
    },

    changeShippingType() {
      if (this.shippingType.trim() == "NO_SHIPPING") {
        this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/elimina-envio`);
        this.requestApi({
          method: "PATCH",
          data: {},
        }).then(() => {
          this.openQutation();
        });
      } else {
        this.storeInfohipping();
        //this.changeShippingMethodSelected(true,true,true)
      }
    },

    changeShippingMethodSelected(
      updateShippingMethodType = true,
      createAdjustment = true,
      updatePriceShippingMethod = true
    ) {
      this.loadingShippingMethodSelected = true;

      if (this.shippingType != "NO_SHIPPING" && !updatePriceShippingMethod) {
        this.shippingType == this.dataQuotation.valorEnvio;
      } else if (
        this.shippingType != "NO_SHIPPING" &&
        updatePriceShippingMethod
      ) {
        this.priceShipment =
          this.shippingMethodSelected.price == null ||
          this.shippingMethodSelected.price == ""
            ? 0
            : this.shippingMethodSelected.price;
      } else if (this.shippingType == "NO_SHIPPING") {
        this.priceShipment = 0;
      }

      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/metodo-envio`);
      this.requestApi({
        method: "PATCH",
        data: {
          shipmentMethodTypeId: this.shippingMethodSelected.shipmentMethodId,
          carrierPartyId: this.shippingMethodSelected.carrierPartyId,
          priceShipment: this.priceShipment,
          updateShippingMethodType,
          createAdjustment,
          updatePriceShippingMethod,
          runPromotions: !this.returnQuotation && updateShippingMethodType,
        },
      }).then((res) => {
        //console.log(res);

        this.priceShipment = res.data.detail.amount;
        this.loadingShippingMethodSelected = false;

        this.updateTablePrices();
      });
    },

    changeDireccionSelected() {
      this.loadingDireccionSelected = true;
      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/direccion-envio`);
      this.requestApi({
        method: "PATCH",
        data: {
          contactMechIdDireccion: this.directionSelected,
          attnName:
            this.attnName == null || this.attnName == ""
              ? this.dataQuotation.partyNombres
              : this.attnName,
        },
      }).then((res) => {
        console.log(res);
        this.pointShippingMap(false);
        this.loadingDireccionSelected = false;
        this.shippingMethodAvailable(
          res.data.detail.latitude,
          res.data.detail.longitude
        );
      });
    },

    shippingMethodAvailable(lat, lng, createAdjustment = true) {
      let geohash = Geohash.encode(lat, lng, 9);
      console.log("geohash", geohash);
      let availables = [];
      this.dataRequestQuotation.metodosEnvio = [];
      this.shippingMethods.forEach((e) => {
        e.geohashs.forEach((gh) => {
          if (
            gh.geohash == geohash.substring(0, 5) ||
            gh.geohash == geohash.substring(0, 6) ||
            gh.geohash == geohash.substring(0, 7)
          )
            availables.push(e);
        });
      });
      console.log("availables", availables);
      if (availables.length == 1) {
        this.dataRequestQuotation.metodosEnvio = availables;
        this.shippingMethodSelected = availables[0];
        this.changeShippingMethodSelected(true, createAdjustment, true);
      } else if (!availables.length && this.shippingType == "SHIPPING") {
        Vue.swal({
          html: `No se encontraron métodos de envío para la ubicación seleccionada`,
          icon: "warning",
        });
      }

      this.dataRequestQuotation.metodosEnvio = availables;
    },

    pointShippingMap(setSM = true) {
      this.pointShippment = [];
      let objSP = this.dataRequestQuotation.dirsCliente.find(
        (e) => e.contactmechid == this.directionSelected
      );

      if (typeof objSP != "undefined" && objSP.geo_point_id != null) {
        setTimeout(() => {
          this.pointShippment.push({
            latLng: [objSP.latitude, objSP.longitude],
            label: objSP.direccion_geo_point,
          });
        }, 1000);
      } else {
        if (typeof this.dataDirection.name != "undefined") {
          this.objGeoCoding.provider
            .search({ query: this.dataDirection.name })
            .then((res) => {
              console.log("hola", res);
              this.pointShippment.push({
                latLng: [res[0].y, res[0].x],
                label: res[0].label,
              });
            });
        }
      }

      if (setSM) {
        this.dataShippmentMethods().then(() => {
          if (
            this.dataQuotation.latitude != null &&
            this.dataQuotation.longitude != null
          )
            this.shippingMethodAvailable(
              this.dataQuotation.latitude,
              this.dataQuotation.longitude,
              false
            );
        });
      }
    },

    changeDeadlineSelected() {
      this.loadingDeadlineSelected = true;
      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/plazo`);
      this.requestApi({
        method: "PATCH",
        data: {
          plazoId: this.deadlineSelected,
        },
      }).then((res) => {
        this.loadingDeadlineSelected = false;
      });
    },

    changePhoneSelected() {
      this.loadingPhoneSelected = true;
      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/telefono`);
      this.requestApi({
        method: "PATCH",
        data: {
          contactMechIdTelefono: this.phoneSelected,
        },
      }).then((res) => {
        this.loadingPhoneSelected = false;
      });
    },

    changeEmailSelected() {
      this.loadingEmailSelected = true;
      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/email`);
      this.requestApi({
        method: "PATCH",
        data: {
          contactMechIdEmail: this.emailSelected,
          orderId: this.orderId,
        },
      }).then((res) => {
        this.loadingEmailSelected = false;
      });
    },

    changePrescriptorSelected() {
      this.loadingPrescriptorSelected = true;
      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/prescriptor`);
      this.requestApi({
        method: "PATCH",
        data: {
          partyIdPrescriptor: this.prescriberSelected,
          orderId: this.orderId,
        },
      }).then((res) => {
        this.loadingPrescriptorSelected = false;
      });
    },

    relatePrescriber(prescriber) {
      Vue.swal({
        html: `Está seguro de relacionar el prescriptor ${prescriber.nombres} con el cliente? `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingPrescriptorSelected = true;
          this.setUrl(
            `cotizacion/${this.dataQuotation.quoteId}/relaciona-prescriptor`
          );
          this.requestApi({
            method: "PATCH",
            data: {
              partyIdPrescriber: prescriber.partyid,
              orderId: this.orderId,
            },
          }).then((res) => {
            this.relatedPrescribers.push(prescriber);
            this.prescriberSelected = prescriber.partyid;
            this.dialogPrescriber = false;
            this.loadingPrescriptorSelected = false;
          });
        }
      });
    },

    changePatientSelected() {
      this.loadingPatientSelected = true;
      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/paciente`);
      this.requestApi({
        method: "PATCH",
        data: {
          party_id: this.patientSelected,
          orderId: this.orderId,
        },
      }).then((res) => {
        this.loadingPatientSelected = false;
      });
    },

    changeUbicationSelected() {
      this.loadingEstablishmentSelected = true;
      this.setUrl(
        `cotizacion/${this.dataQuotation.quoteId}/actualiza-establecimiento`
      );
      this.requestApi({
        method: "PATCH",
        data: {
          productStoreId: this.establishmentSelected,
        },
      }).then((res) => {
        this.setUbication(this.establishmentSelected);
        this.setShippingMethods(res.data.detail);
        this.openQutation();
        this.loadingEstablishmentSelected = false;
      });
    },

    storePhone(data) {
      console.log(data);
      if (data.res.status === 201) {
        this.phones.unshift({
          cmId: "",
          cmId2: data.res.data.contactMechId,
          phone: data.tlf,
        });

        this.phoneSelected = data.res.data.contactMechId;
      }
    },

    storeEmail(data) {
      if (data.res.status === 201) {
        this.emails.unshift({
          emailId: data.res.data.contactMechId,
          email: data.email,
        });

        this.emailSelected = data.res.data.contactMechId;
        this.changeEmailSelected();
      }
    },

    storePatient(data) {
      let obj = this.patients.find((e) => e.party_id == data.res.data.party_id);
      typeof obj == "undefined" && this.patients.unshift(data.res.data);
      this.patientSelected = data.res.data.party_id;
    },

    storeClient(data) {
      console.log(data);
      let obj = this.clients.find((e) => e.party_id == data.partyId);
      typeof obj == "undefined" &&
        this.clients.unshift({ party_id: data.partyId, name: data.name });
      this.dataQuotation.partyId = data.partyId;
      this.changeClientSelected();
    },

    storeDirection(data) {
      if (data.res.status === 201) {
        if (data.action == "billing") {
          this.billingAddress.unshift({
            direccionId: data.res.data.contactMechId,
            name: data.direction,
          });

          this.dataQuotation.direccionId = data.res.data.contactMechId;
          this.changeBillingAddress();
        }

        if (data.action != "billing" || this.shippingType == "NO_SHIPPING") {
          this.directions.unshift({
            direccionId: data.res.data.contactMechId,
            name: data.direction,
            attnName: data.attnName,
          });

          this.directionSelected = data.res.data.contactMechId;
          this.pointShippment = data.point;
          this.changeDireccionSelected();
        }
      }
    },

    updateTablePrices(sequenceId = "", update) {
      if (
        typeof this.dataQuotation.items != "undefined" &&
        this.dataQuotation.items.length > 0
      ) {
        let subTotal = 0;
        let subTotalImp0 = 0;
        let subTotalImp = 0;
        let totalImp = 0;
        let total = 0;
        let totalPvP = 0;
        let cash = 0;
        let currentTC = 0;
        let deferredTD = 0;
        let debitCard = 0;
        let imp = 0;
        let newQuantity = 1;
        let quoteUnitPrice = 0;
        let sumPQuoted = 0;
        let sumCostoTotal = 0;
        let precioModificado = false;

        for (let item of this.dataQuotation.items) {
          let subTotal1 = 0;
          let obj = this.productsQuotation.find(
            (e) => e.seqId == item.itemSeqId
          );

          if (typeof obj != "undefined") {
            if (obj.seqId == sequenceId) {
              newQuantity = obj.quantity;
              quoteUnitPrice = obj.pQuoted;

              if (update == "precio") {
                precioModificado = true;
                this.alertNotification({
                  param: {
                    html: `Precio del ${item.descripcion} ha sido modificado   a ${obj.pQuoted} `,
                    timer: 10000,
                  },
                });
              } else {
                precioModificado = false;
              }
            }

            if (parseFloat(obj.pQuoted) > parseFloat(item.precioPvp)) {
              this.alertNotification({
                param: {
                  html: `El precio del ${item.descripcion} no puede ser mayor a su P.V.P: ${item.precioPvp}`,
                  timer: 10000,
                },
              });
              quoteUnitPrice = item.precioPvp;
              obj.pQuoted = item.precioPvp;
            }

            let index = this.productsQuotation.indexOf(obj);
            this.productsQuotation[index].total = (
              obj.quantity *
              (obj.pQuoted - obj.discount)
            ).toFixed(2);

            subTotal1 = parseFloat(obj.quantity * (obj.pQuoted - obj.discount));

            imp = subTotal1 * (parseFloat(obj.pctImp) / 100);
            let pvp = parseFloat(
              //(item.precioPvp - obj.discount) * obj.quantity
              item.precioPvp * obj.quantity
            );
            obj.pctImp == 0
              ? (subTotalImp0 += subTotal1)
              : (subTotalImp += subTotal1);

            subTotal += subTotal1;
            totalImp += imp;
            totalPvP += pvp;

            if (parseFloat(item.precioDef) > parseFloat(item.precioPvp)) {
              cash += parseFloat(
                //(item.precioPvp - obj.discount) * obj.quantity
                item.precioPvp * obj.quantity
              );
            } else {
              cash += parseFloat(
                //(item.precioDef - obj.discount) * obj.quantity + imp
                item.precioDef * obj.quantity // + imp
              );
            }

            if (parseFloat(item.precioTcc) > parseFloat(item.precioPvp)) {
              currentTC += parseFloat(
                //(item.precioPvp - obj.discount) * obj.quantity
                item.precioPvp * obj.quantity
              );
            } else {
              currentTC += parseFloat(
                //(item.precioTcc - obj.discount) * obj.quantity + imp
                item.precioTcc * obj.quantity //+ imp
              );
            }

            if (parseFloat(item.precioTcd) > parseFloat(item.precioPvp)) {
              deferredTD += parseFloat(
                //(item.precioPvp - obj.discount) * obj.quantity
                item.precioPvp * obj.quantity
              );
            } else {
              deferredTD += parseFloat(
                //(item.precioTcd - obj.discount) * obj.quantity + imp
                item.precioTcd * obj.quantity //+ imp
              );
            }

            if (parseFloat(item.precioTd) > parseFloat(item.precioPvp)) {
              debitCard += parseFloat(
                //(item.precioPvp - obj.discount) * obj.quantity
                item.precioPvp * obj.quantity
              );
            } else {
              debitCard += parseFloat(
                //(item.precioTd - obj.discount) * obj.quantity + imp
                item.precioTd * obj.quantity //+ imp
              );
            }

            let marginItem = obj.pQuoted - item.costoTotal;
            let pctMargin = (marginItem * 100) / (obj.pQuoted - obj.discount);
            sumPQuoted += parseFloat(obj.pQuoted - obj.discount);
            sumCostoTotal += parseFloat(item.costoTotal);
            this.productsQuotation[index].color =
              this.getColorMargin(pctMargin);
            this.productsQuotation[index].margin = pctMargin.toFixed(2);
          }
        }

        let marginTotal = sumPQuoted - sumCostoTotal;
        let pctMarginTotal = (marginTotal * 100) / sumPQuoted;

        this.quoteMarginColor = this.getColorMargin(pctMarginTotal);
        total = subTotalImp0 + subTotalImp + parseFloat(totalImp);

        Object.assign(this.paymentAmounts, {
          total: (total + parseFloat(this.priceShipment)).toFixed(2),
          subTotalImp: subTotalImp,
          subTotalImp0: subTotalImp0.toFixed(2),
          totalImp: totalImp,
          subTotal: subTotal.toFixed(2),
          cash: (cash + parseFloat(this.priceShipment)).toFixed(2),
          currentTC: (currentTC + parseFloat(this.priceShipment)).toFixed(2),
          totalSaving: (totalPvP - total).toFixed(2),
          deferredTD: (deferredTD + parseFloat(this.priceShipment)).toFixed(2),
          debitCard: (debitCard + parseFloat(this.priceShipment)).toFixed(2),
          totalPvP: (totalPvP + parseFloat(this.priceShipment)).toFixed(2),
        });
        /* console.log(
          "#####TOTALES:" +
            total +
            " ## " +
            subTotalImp +
            " ## " +
            subTotalImp0 +
            "##" +
            totalImp +
            "## " +
            this.priceShipment
        );*/
        if (sequenceId != "") {
          this.setUrl(
            `cotizacion/${this.dataQuotation.quoteId}/actualiza-item`
          );
          this.requestApi({
            method: "PATCH",
            data: {
              quoteId: this.dataQuotation.quoteId,
              quoteItemSeqId: sequenceId,
              newQuantity: newQuantity,
              quoteUnitPrice: quoteUnitPrice,
              precioModificado: precioModificado,
            },
          }).then(() => {
            this.openQutation();
          });
        }
      }
    },

    getColorMargin(pctMargen) {
      let color = "#fff";
      if (pctMargen > 25) {
        //dorado
        color = "#fcdc3f";
      }
      if (pctMargen > 20 && pctMargen <= 25) {
        //verde
        color = "#8eed99";
      }
      if (pctMargen > 17 && pctMargen <= 20) {
        //cafe
        color = "#d3b67c";
      }
      if (pctMargen > 12 && pctMargen <= 17) {
        //naranja
        color = "#ffc560";
      }
      if (pctMargen > 0 && pctMargen <= 12) {
        //amarillo
        color = "#f9fca9";
      }
      if (pctMargen <= 0) {
        //rojo
        color = "#fc9494";
      }
      return color;
    },

    storeInventory(item) {
      this.setUrl(`cotizacion-item/${item.product}`);
      this.requestApi({
        method: "GET",
        data: {
          producStoreId: this.establishmentSelected,
        },
      }).then((res) => {
        this.inventoryItemSelected = item;
        this.storeInventories = res.data.stores;
        this.dialogStoreInventory = true;
      });
    },

    infoPromo(item) {
      this.setUrl(`info-producto/${item.product}`);
      this.requestApi({
        method: "GET",
        data: {
          producStoreId: this.establishmentSelected,
        },
      }).then((res) => {
        console.log(res.data.detail);
        this.infoProd = res.data.detail.info;
        this.datosPromo = res.data.detail.promos;
        this.dialogInfoProd = true;
        this.mostrarPromo = true;
      });
    },

    infoProducto(item) {
      this.setUrl(`info-producto/${item.product}`);
      this.requestApi({
        method: "GET",
        data: {
          producStoreId: this.establishmentSelected,
        },
      }).then((res) => {
        console.log(res.data.detail);
        this.infoProd = res.data.detail.info;
        this.datosPromo = {};
        this.dialogInfoProd = true;
        this.mostrarPromo = false;
      });
    },

    storeAditionalInfo() {
      clearInterval(intervalAditionlInfo);

      intervalAditionlInfo = setTimeout(() => {
        this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/info-adicional`);
        this.requestApi({
          method: "PATCH",
          data: {
            info1: this.info1,
            info2: this.info2,
            info3: this.info3,
            guia: this.guia,
            description: this.description,
          },
        }).then((res) => {
          console.log(res);
        });
      }, 1000);
    },

    point(point) {
      this.pointShippment = point;
    },

    updatePointShipping(data) {
      this.setUrl("datos-person");
      this.requestApi({
        method: "PATCH",
        data: {
          ...data,
          contact_mech_id: this.directionSelected,
          party_id: this.dataQuotation.partyId,
          quote_id: this.dataQuotation.quoteId,
        },
      }).then((res) => {
        let objDirsCliente = this.dataRequestQuotation.dirsCliente.find(
          (e) => e.contactmechid == res.data.detail.contactMechId
        );
        let objDirections = this.directions.find(
          (e) => e.direccionId == res.data.detail.contactMechId
        );

        if (typeof objDirsCliente != "undefined") {
          let index =
            this.dataRequestQuotation.dirsCliente.indexOf(objDirsCliente);
          this.dataRequestQuotation.dirsCliente[index].direccion_geo_point =
            res.data.detail.direction;
          this.dataRequestQuotation.dirsCliente[index].latitude =
            data.point.lat;
          this.dataRequestQuotation.dirsCliente[index].longitude =
            data.point.lng;
        } else {
          this.dataRequestQuotation.dirsCliente.unshift({
            direccion_geo_point: res.data.detail.direction,
            latitude: data.point.lat,
            longitude: data.point.lng,
          });
        }

        if (typeof objDirections != "undefined") {
          let index = this.directions.indexOf(objDirections);
          this.directions[index].direccionId = res.data.detail.contactMechId;
          this.directions[index].name = res.data.detail.direction;
        } else {
          this.directions.unshift({
            direccionId: res.data.detail.contactMechId,
            name: res.data.detail.direction,
          });
        }

        this.directionSelected = res.data.detail.contactMechId;
        this.shippingMethodAvailable(data.point.lat, data.point.lng);
      });
    },

    cancelQuote() {
      if (!this.$refs.form_cancel_quotation.validate()) return false;

      this.setOverlay(true);
      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}`);
      this.requestApi({
        method: "PUT",
        data: {
          reason: this.reasonCancel,
          description: this.descriptionCancel,
          quoteId: this.dataQuotation.quoteId,
          orderId: this.orderId,
        },
      })
        .then((res) => {
          console.log(res);
          this.openQutation();

          if (res.data.msg != "") {
            this.alertNotification({
              param: {
                html: res.data.detail.msg,
              },
            });
          }

          if (
            res.data.detail.success &&
            this.orderId != "" &&
            this.orderId != null
          )
            window.frames["framePrint"].location.replace(
              `https://${this.ipPrinter}/impresion/escpos-php/recibos/cancelar_cotizacion.php?orderId=${this.orderId}&quoteId=${this.dataQuotation.quoteId}&userId=${this.user.user_ligin_id}&tenantId=${this.tenantId}&impresora=${this.printer}&puerto_impresora=${this.portPrinter}`
            );

          this.closeDialogCancelQuotation();
          this.setOverlay(false);
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },

    closeDialogCancelQuotation() {
      this.dialogCancelQuotation = false;
      this.reasonCancel = null;
      this.descriptionCancel = null;
    },

    getClients() {
      this.setUrl("cotizacion");
      this.requestApi({
        method: "PATCH",
        data: {},
      }).then((res) => {
        this.clients = res.data._embedded.cotizacion;
      });
    },

    changeClientSelected() {
      const { agreementId } = this.dataQuotation;

      if (agreementId != null && agreementId != "") {
        Vue.swal({
          html: "El cliente anterior tiene un acuerdo si lo cambia los precios de los producto podrían verse afectado en el precio",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cerrar",
          ...this.paramAlertQuestion,
        }).then((result) => {
          result.isConfirmed && this.changeClient();
        });
      } else {
        this.changeClient();
      }
    },

    changeClient() {
      const { partyId, quoteId } = this.dataQuotation;

      this.setUrl("cotizacion");
      this.requestApi({
        method: "PUT",
        data: {
          quoteId,
          partyId,
        },
      }).then((res) => {
        console.log(res);
        let objParty = this.clients.find((e) => e.party_id == partyId);
        this.dataQuotation.partyNombres = objParty.name;
        this.dataQuotation.partyCedula = "";
        this.openQutation();
      });
    },

    storeInfohipping() {
      clearInterval(intervalInfoShipping);

      intervalInfoShipping = setTimeout(() => {
        this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/info-shipping`);
        this.requestApi({
          method: "PATCH",
          data: {
            instructions: this.instructions,
            sendAfter: this.sendAfter,
            deliverBefore: this.deliverBefore,
            guia: this.guia,
            attnName: this.attnName,
            tlfAttnName: this.tlfAttnName,
            contactMechIdDirection: this.directionSelected,
            orderId: this.orderId,
          },
        }).then((res) => {
          console.log(res);
        });
      }, 1000);
    },

    goPaymentDetails() {
      let valid = this.validateGeneralDataAndShipping;

      if (!valid.success) {
        Vue.swal({
          html: valid.message,
          icon: "warning",
          ...this.paramAlertQuestion,
        });
        return false;
      }

      this.setOverlay(true);
      this.setUrl("estado-cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          typeStore: "goPaymentDetails",
          wayToPaySelected: this.wayToPaySelected,
          quoteId: this.dataQuotation.quoteId,
          partyId: this.dataQuotation.partyId,
          creteOrderPaymentPreference: this.dataQuotation.paymentsInFavor.length
            ? "N"
            : "Y",
          paymentsInFavor: this.dataQuotation.paymentsInFavor,
          totalQuote: this.paymentAmounts.total,
        },
      })
        .then((res) => {
          console.log(res);

          this.openQutation().then(() => {
            if (
              ["PLACE_TO_PAY_DC", "PLACE_TO_PAY_CC"].includes(
                this.wayToPaySelected
              )
            ) {
              this.dataQuotation.payments.push({
                manual_ref_num: null,
                max_amount: null,
                order_payment_preference_id: null,
                payment_status: null,
                payment_type: null,
                payment_method_id: null,
                payment_method_type_id: this.wayToPaySelected,
              });
            }
          });

          this.alertNotification({
            param: {
              title: res.data.res.acceso ? "Éxito" : "Cotización en aprobación",
              icon: res.data.res.acceso ? "success" : "info",
              html: res.data.res.msg,
              timer: res.data.res.acceso ? 5000 : 20000,
              toast: res.data.res.acceso,
            },
          });
          this.orderId = res.data.res.orderId;

          if (res.data.res.acceso && this.orderId != "" && this.orderId != null)
            this.printCommand(0);

          this.setOverlay(false);
        })
        .catch(() => {
          this.setOverlay(false);
        })
        .then(() => {
          this.setOverlay(false);
        });
    },

    deletePaymentQuote(payment) {
      console.log(payment);

      Vue.swal({
        html: "Esta seguro de eliminar el pago de la cotización?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          if (payment.order_payment_preference_id != "") {
            this.setUrl(
              `cotizacion/${this.dataQuotation.quoteId}/eliminar-pago`
            );
            this.requestApi({
              method: "PATCH",
              data: payment,
            }).then((res) => {
              console.log(res);

              this.openQutation();
              /* let index = this.dataQuotation.payments.indexOf(payment)
                            this.dataQuotation.payments[index].order_payment_preference_id = res.data.detail.orderPaymentPreferenceId */

              if (res.data.msg != "") {
                this.alertNotification({
                  param: {
                    html: res.data.detail.msg,
                  },
                });
              }
            });
          }

          let index = this.dataQuotation.payments.indexOf(payment);
          this.dataQuotation.payments.splice(index, 1);
        }
      });
    },

    setCreditCardQuote(payment) {
      this.setUrl(
        `cotizacion/${this.dataQuotation.quoteId}/cambia-tarjeta-credito`
      );
      this.requestApi({
        method: "PATCH",
        data: {
          orderPaymentPreferenceId: payment.order_payment_preference_id,
          paymentMethodId: payment.payment_method_id,
        },
      });
    },

    copyQuote() {
      Vue.swal({
        html: "Esta seguro de copiar la cotización?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("estado-cotizacion");
          this.requestApi({
            method: "POST",
            data: {
              typeStore: "copyQuote",
              quoteId: this.dataQuotation.quoteId,
            },
          }).then((res) => {
            console.log(res);
            this.openQutation();

            if (res.data.msg != "") {
              this.alertNotification({
                param: {
                  html: res.data.res.msg,
                },
              });
              if (res.data.res.quoteId != null && res.data.res.quoteId != "")
                window.location.href = "/cotizacion/" + res.data.res.quoteId;
            }
          });
        }
      });
    },

    storeNewPayment(payment) {
      let validPayment = this.validActionPayment(payment);

      if (validPayment.validation) {
        Vue.swal({
          html: validPayment.caseValidation,
          ...this.paramAlertQuestion,
        });
        return false;
      }

      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/guarda-pago`);
      this.requestApi({
        method: "PATCH",
        data: {
          ...payment,
          orderId: this.orderId,
          quoteId: this.dataQuotation.quoteId,
        },
      }).then((res) => {
        this.openQutation();

        if (res.data.msg != "") {
          this.alertNotification({
            param: {
              html: res.data.detail.msg,
            },
          });
        }
      });
    },

    dispatchQuote() {
      let validRecip = false;
      this.productsQuotation
        .filter((e) => e.psicotropic == "SI")
        .forEach((obj, i) => {
          if (obj.recip == "" || obj.recip == null || obj.recip.length < 8) {
            Vue.swal({
              html: `Debe ingresar el número de receta de 8 dígitos en el producto: <br /> ${obj.description}`,
              icon: "warning",
              ...this.paramAlertQuestion,
            });
            validRecip = true;
            return false;
          }
        });
      if (validRecip) return false;

      let authorize = [
        "CREDIT_CARD_DIF_INT",
        "DEBIT_CARD",
        "FIN_ACCOUNT",
        "CREDIT_CARD",
        "EXT_COD",
        "EFT_ACCOUNT",
        "CREDIT_CARD_DIF",
        "PLACE_TO_PAY_CC",
        "PLACE_TO_PAY_DC",
      ];

      let validPayments = false;
      this.dataQuotation.payments.forEach((obj) => {
        if (
          authorize.includes(obj.payment_method_type_id) &&
          obj.status_id != "PAYMENT_AUTHORIZED"
        ) {
          Vue.swal({
            html: `El pago de $${obj.max_amount} no esta autorizado`,
            icon: "warning",
            ...this.paramAlertQuestion,
          });
          validPayments = true;
          return false;
        }
      });
      if (validPayments) return false;

      this.setOverlay(true);
      this.setUrl("estado-cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeDispatchQuote",
          orderId: this.orderId,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              title: res.data.res.success ? "Éxito" : "Error",
              icon: res.data.res.success ? "success" : "warning",
              html: res.data.res.msg,
              timer: res.data.res.success ? 5000 : 20000,
              toast: res.data.res.success,
            },
          });

          this.setOverlay(false);
          this.printSalesReceipt();
          this.openQutation();
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },

    searchBarCodeItem() {
      clearInterval(intervalBarCode);

      intervalBarCode = setTimeout(() => {
        let obj = this.dataQuotation.items_order.find(
          (e) => e.codigoBarra == this.barCodeItem && e.verificado == false
        );

        if (typeof obj != "undefined") {
          let index = this.dataQuotation.items_order.indexOf(obj);
          this.dataQuotation.items_order[index].verificado = true;
        } else {
          Vue.swal({
            html: "El producto escaneado no pertence a la cotización",
            icon: "warning",
            ...this.paramAlertQuestion,
          });
        }

        this.setUrl("estado-cotizacion");
        this.requestApi({
          method: "POST",
          data: {
            typeStore: "storeProductCheck",
            status: typeof obj != "undefined",
            quoteId: this.dataQuotation.quoteId,
            productId: typeof obj != "undefined" ? obj.productId : null,
            barCodeItem: this.barCodeItem,
            seqId: typeof obj != "undefined" ? obj.seqId : null,
            allVerified:
              this.dataQuotation.items_order.length ==
              this.dataQuotation.items_order.filter((e) => e.verificado == true)
                .length,
          },
        });

        this.barCodeItem = null;
      }, 200);
    },

    addRecipe() {
      this.dataQuotation.recipes.push({
        picture: "",
        number: null,
      });
    },

    openCamera(index) {
      this.$refs.cam[index].start();
    },

    takePictureRecip(index) {
      this.$refs.cam[index].snap();

      this.setUrl("estado-cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeFileQuote",
          quoteId: this.dataQuotation.quoteId,
          file: this.dataQuotation.recipes[index].picture,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
      });

      console.log(this.dataQuotation.recipes);
    },

    openDialogCreditCard(payment) {
      this.payment = payment;
      this.dialogCreditCard = true;
    },

    closeDialogCreditCard() {
      this.dialogCreditCard = false;
      Object.assign(this.dataStoreCreditCard, this.defaultDataStoreCreditCard);
    },

    storeCreditCard() {
      if (!this.$refs.form_data_credit_card.validate()) return false;

      this.setOverlay(true);
      this.setUrl("estado-cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeCreditCard",
          partyId: this.dataQuotation.partyId,
          orderPaymentPreferenceId: this.payment.order_payment_preference_id,
          orderId: this.orderId,
          ...this.dataStoreCreditCard,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
        if (res.data.res.existsCCParty == null) {
          this.dataQuotation.creditCards.push({
            card_type: res.data.res.cardType,
            card_number: res.data.res.cardNumber,
            payment_method_id: res.data.res.paymentMethodId,
          });
        }

        if (typeof this.payment.order_payment_preference_id != "undefined") {
          let index = this.dataQuotation.payments.indexOf(this.payment);
          this.dataQuotation.payments[index].payment_method_id =
            res.data.res.paymentMethodId;
        }
        //this.closeDialogCreditCard()
        this.setOverlay(false);
      });
    },

    returnQuote() {
      Vue.swal({
        html: `Está seguro de editar esta cotización? si la cotización tiene varios pagos deberá ingresarlos nuevamente`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setOverlay(true);
          this.setUrl("estado-cotizacion");
          this.requestApi({
            method: "POST",
            data: {
              typeStore: "returnQuote",
              quoteId: this.dataQuotation.quoteId,
              orderId: this.orderId,
            },
          }).then((res) => {
            console.log(res);
            this.alertNotification({
              param: {
                html: res.data.res.msg,
              },
            });

            this.openQutation();
            this.setOverlay(false);
            this.tab = 0;
          });
        }
      });
    },

    openQutation() {
      return new Promise((resolve, rejected) => {
        this.setOverlay(true);
        this.setUrl("cotizacion");

        this.requestApi({
          method: "GET",
          data: {
            quoteId: this.$route.params.cotizacion_id,
          },
        })
          .then((res) => {
            this.dataRequestQuotation = res.data;
            this.codigosPromos = res.data.codigosPromo;
            this.cupones = res.data.codigosPromoCotizacion;
            //console.log(res.data.codigosPromo[0]);
            this.dialogDetail = true; // Abre el dialog
            this.setOverlay(false); // Cierra el overlay
            this.dialogSearchClient = false;
            resolve(res);
          })
          .catch((err) => {
            this.setOverlay(false);
            rejected(err);
          });
      });
    },

    openDialogCreditCardData(payment) {
      this.setOverlay(true);
      this.setUrl("estado-cotizacion/" + payment.payment_method_id);
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.dialogCreditCardPaymentData = true;
          Object.assign(this.creditCardPaymentData, res.data.creditCard);

          this.setOverlay(false);
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },

    closeDialogCreditCardData() {
      Object.assign(
        this.creditCardPaymentData,
        this.creditCardPaymentDataDefault
      );
      this.dialogCreditCardPaymentData = false;
    },

    validActionPayment(payment) {
      let validation = false;
      let caseValidation = "";

      if (
        payment.max_amount == "" ||
        payment.max_amount == null ||
        payment.max_amount == 0
      ) {
        validation = true;
        caseValidation = "Debe escribir un monto mayor a 0 para el pago";
      } else if (
        payment.payment_method_type_id == "" ||
        payment.payment_method_type_id == null
      ) {
        validation = true;
        caseValidation = "Debe seleccionar el método de pago";
      } else if (
        payment.payment_method_type_id != "CASH" &&
        payment.payment_method_type_id != "PERSONAL_CHECK" &&
        (payment.manual_ref_num == "" || payment.manual_ref_num == null)
      ) {
        validation = true;
        caseValidation = "Debe escribir la referencia del pago";
      }

      return {
        validation,
        caseValidation,
      };
    },

    confirmCrediCardPayment(payment) {
      let validPayment = this.validActionPayment(payment);

      if (validPayment.validation) {
        Vue.swal({
          html: validPayment.caseValidation,
          ...this.paramAlertQuestion,
        });
        return false;
      }

      Vue.swal({
        html: `Está seguro de confirmar este pago? <br /> Una vez confirmado sólo podrá cambiar a referencia`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setOverlay(true);
          this.setUrl("estado-cotizacion");
          this.requestApi({
            method: "POST",
            data: {
              typeStore: "confirmCreditCardPayment",
              partyIdFrom: this.dataQuotation.partyId,
              partyIdTo: this.ubication,
              quoteId: this.dataQuotation.quoteId,
              ...payment,
            },
          })
            .then((res) => {
              this.setOverlay(false);
              this.openQutation();
            })
            .catch(() => {
              this.setOverlay(false);
            });
        }
      });
    },

    readDataCreditCard(evt) {
      clearInterval(intervalCreditCard);

      intervalCreditCard = setTimeout(() => {
        console.log("entrada" + String.fromCharCode(evt.keyCode));
        if (String.fromCharCode(evt.keyCode) == "\r") {
          let first_name = "";
          let last_name = "";
          let expiration = "";
          let arrName = [];

          let card_data = this.dataStoreCreditCard.first_name_on_card;
          let number = card_data.split("&")[0].substring(2);
          let name = card_data.split("&")[1].replace("-", " ").trim();
          arrName = name.split(" ");

          expiration =
            typeof card_data.split("¿")[1] != "undefined"
              ? card_data.split("¿")[1].split("-")[0].substring(0, 4)
              : card_data.split("¡")[1].split("-")[0].substring(0, 4);

          if (arrName.length >= 3) {
            first_name = arrName[0] + arrName[1];

            for (let i = 2; i < arrName.length; i++)
              last_name += arrName[i] + " ";
          } else {
            first_name = arrName[0];
            last_name = typeof arrName[1] != "undefined" ? arrName[1] : "";
          }

          expiration =
            "20" +
            expiration[0] +
            expiration[1] +
            "-" +
            expiration[2] +
            expiration[3];

          this.dataStoreCreditCard.first_name_on_card = first_name;
          this.dataStoreCreditCard.last_name_on_card = last_name;
          this.dataStoreCreditCard.card_number = number;
          this.dataStoreCreditCard.expire_date = expiration;

          if (
            (number.length == 16 || number.length == 13) &&
            number.substring(0, 1) == "4"
          ) {
            this.dataStoreCreditCard.enum_id = "CCT_VISA";
            //this.storeCreditCard()
            return;
          }

          let firstdig = parseInt(number.substring(0, 1));
          let seconddig = parseInt(number.substring(1, 2));

          if (
            number.length == 16 &&
            firstdig == 5 &&
            seconddig >= 1 &&
            seconddig <= 5
          ) {
            this.dataStoreCreditCard.enum_id = "CCT_MASTERCARD";
            //this.storeCreditCard()
            return;
          }

          firstdig = parseInt(number.substring(0, 1));
          seconddig = parseInt(number.substring(1, 2));

          if (
            number.length == 15 &&
            firstdig == 3 &&
            (seconddig == 4 || seconddig == 7)
          ) {
            this.dataStoreCreditCard.enum_id = "CCT_AMERICANEXPRESS";
            //this.storeCreditCard()
            return;
          }

          firstdig = parseInt(number.substring(0, 1));
          seconddig = parseInt(number.substring(1, 2));

          if (
            number.length == 14 &&
            firstdig == 3 &&
            (seconddig == 0 || seconddig == 6 || seconddig == 8)
          ) {
            this.dataStoreCreditCard.enum_id = "CCT_DINERSCLUB";
            //this.storeCreditCard()
            return;
          }

          let first4digs = number.substring(0, 4);
          let first2digs = number.substring(0, 2);
          let first3digs = number.substring(0, 3);
          let sec3digs = parseInt(number.substring(3, 5));

          if (
            number.length == 16 &&
            (first4digs == "6011" ||
              first2digs == "65" ||
              first3digs == "649" ||
              first3digs == "648" ||
              first3digs == "647" ||
              first3digs == "646" ||
              first3digs == "645" ||
              first3digs == "644" ||
              (first3digs == "622" && sec3digs >= 126 && sec3digs <= 925))
          ) {
            this.dataStoreCreditCard.enum_id = "CCT_DISCOVER";
            //this.storeCreditCard()
            return;
          }
        }
      }, 500);
    },
    abrirDialogoConfirmarTransferencia(payment) {
      this.finAccountId = "";
      this.fechaTransfe = new Date().toISOString().substring(0, 10);
      this.pagoConfirmar = payment;
      this.dialogoTransferencia = true;
    },

    confirmarTransferencia() {
      if (!this.$refs.formConfirmarTransferencia.validate()) {
        return false;
      }
      let validPayment = this.validActionPayment(this.pagoConfirmar);

      if (validPayment.validation) {
        Vue.swal({
          html: validPayment.caseValidation,
          ...this.paramAlertQuestion,
        });
        return false;
      }

      this.setOverlay(true);
      this.setUrl("estado-cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          typeStore: "confirmTransferPayment",
          quoteId: this.dataQuotation.quoteId,
          partyIdTo: this.ubication,
          partyIdFrom: this.dataQuotation.partyId,
          orderId: this.dataQuotation.orderId,
          workEffortId: this.$route.params.work_effort_id,
          finAccountId: this.finAccountId,
          fechaTransfe: this.fechaTransfe,
          ...this.pagoConfirmar,
        },
      })
        .then((res) => {
          this.openQutation();

          this.alertNotification({
            param: {
              title: res.data.res.success ? "Éxito" : "Error",
              icon: res.data.res.success ? "success" : "info",
              html: res.data.res.msg,
              timer: res.data.res.success ? 5000 : 20000,
              toast: res.data.res.success,
            },
          });
          this.dialogoTransferencia = false;

          this.setOverlay(false);
        })
        .catch(() => {})
        .then(() => {
          this.setOverlay(false);
        });
    },
    confirmTransferPayment(payment) {
      let banks = {};
      let finAccountId = null;
      this.dataRequestQuotation.banks.forEach((item) => {
        banks[item.fin_account_id] = item.fin_account_name;
      });

      Vue.swal({
        html: `Está seguro de confirmar este pago? <br /> Seleccione el banco donde se hizo efectivo el pago`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        input: "select",
        inputOptions: banks,
        inputPlaceholder: "Seleccione el banco",
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value == "") {
              resolve("Debe seleccionar el banco");
            } else {
              resolve();
              finAccountId = value;
            }
          });
        },
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          let validPayment = this.validActionPayment(payment);

          if (validPayment.validation) {
            Vue.swal({
              html: validPayment.caseValidation,
              ...this.paramAlertQuestion,
            });
            return false;
          }

          this.setOverlay(true);
          this.setUrl("estado-cotizacion");
          this.requestApi({
            method: "POST",
            data: {
              typeStore: "confirmTransferPayment",
              quoteId: this.dataQuotation.quoteId,
              partyIdTo: this.ubication,
              partyIdFrom: this.dataQuotation.partyId,
              orderId: this.dataQuotation.orderId,
              workEffortId: this.$route.params.work_effort_id,
              finAccountId,
              ...payment,
            },
          })
            .then((res) => {
              this.openQutation();

              this.alertNotification({
                param: {
                  title: res.data.res.success ? "Éxito" : "Error",
                  icon: res.data.res.success ? "success" : "info",
                  html: res.data.res.msg,
                  timer: res.data.res.success ? 5000 : 20000,
                  toast: res.data.res.success,
                },
              });

              this.setOverlay(false);
            })
            .catch(() => {})
            .then(() => {
              this.setOverlay(false);
            });
        }
      });
    },

    changeBillingAddress() {
      this.loadingDireccionSelected = true;
      this.setUrl(
        `cotizacion/${this.dataQuotation.quoteId}/direccion-facturacion`
      );
      this.requestApi({
        method: "PATCH",
        data: {
          billingAddress: this.dataQuotation.direccionId,
        },
      }).then((res) => {});
    },

    printLabel() {
      this.setOverlay(true);
      this.setUrl(`cotizacion/${this.dataQuotation.quoteId}/imprimir-etqueta`);
      this.requestApi({
        method: "PATCH",
        data: {
          orderId: this.orderId,
          quoteId: this.dataQuotation.quoteId,
        },
      })
        .then((res) => {
          if (res.status === 200)
            if (this.tenantId == "INNOFARMSA") {
              window.frames["framePrint"].location.replace(
                `https://${this.labelIpPrinter}/impresion/zpl/src/print.php?orderId=${this.orderId}&quoteId=${this.dataQuotation.quoteId}&userId=${this.user.user_ligin_id}&tenantId=${this.tenantId}&impresora=${this.labelPrinter}`
              );
            }

          this.setOverlay(false);
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },

    printCommand(reimpreso) {
      //console.log(this.portPrinter)

      this.setOverlay(true);
      //this.setUrl("estado-cotizacion");
      this.setUrl("impresion");

      //this.ipPrinter

      this.requestApi({
        method: "POST",
        data: {
          // typeStore: "reprintCommand",
          quoteId: this.dataQuotation.quoteId,
          orderId: this.orderId,
          //quoteId: this.dataQuotation.quoteId,
          userId: this.user.user_ligin_id,
          tenantId: this.tenantId,
          impresora: this.printer,
          puerto_impresora: this.portPrinter,
          reimpreso: reimpreso,
          formato: "comanda",
        },
      })
        .then(() => {
          //console.log(`https://${this.ipPrinter}/impresion/escpos-php/recibos/comanda.php?orderId=${this.orderId}&quoteId=${this.dataQuotation.quoteId}&userId=${this.user.user_ligin_id}&tenantId=${this.tenantId}&impresora=${this.printer}&puerto_impresora=${this.portPrinter}&reimpreso=${reimpreso}`)
          /*window.frames["framePrint"].location.replace(
            `https://${this.ipPrinter}/impresion/escpos-php/recibos/comanda.php?orderId=${this.orderId}&quoteId=${this.dataQuotation.quoteId}&userId=${this.user.user_ligin_id}&tenantId=${this.tenantId}&impresora=${this.printer}&puerto_impresora=${this.portPrinter}&reimpreso=${reimpreso}`
          );*/

          this.setOverlay(false);
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },

    printSalesReceipt() {
      /*window.frames["framePrint"].location.replace(
        `https://${this.ipPrinter}/impresion/escpos-php/recibos/orden.php?orderId=${this.orderId}&quoteId=${this.dataQuotation.quoteId}&userId=${this.user.user_ligin_id}&tenantId=${this.tenantId}&impresora=${this.printer}&puerto_impresora=${this.portPrinter}`
      );*/
      this.setOverlay(true);
      //this.setUrl("estado-cotizacion");
      this.setUrl("impresion");

      //this.ipPrinter

      this.requestApi({
        method: "POST",
        data: {
          // typeStore: "reprintCommand",
          quoteId: this.dataQuotation.quoteId,
          orderId: this.orderId,
          //quoteId: this.dataQuotation.quoteId,
          userId: this.user.user_ligin_id,
          tenantId: this.tenantId,
          impresora: this.printer,
          puerto_impresora: this.portPrinter,
          reimpreso: false,
          formato: "orden",
        },
      })
        .then(() => {
          //console.log(`https://${this.ipPrinter}/impresion/escpos-php/recibos/comanda.php?orderId=${this.orderId}&quoteId=${this.dataQuotation.quoteId}&userId=${this.user.user_ligin_id}&tenantId=${this.tenantId}&impresora=${this.printer}&puerto_impresora=${this.portPrinter}&reimpreso=${reimpreso}`)
          /*window.frames["framePrint"].location.replace(
            `https://${this.ipPrinter}/impresion/escpos-php/recibos/comanda.php?orderId=${this.orderId}&quoteId=${this.dataQuotation.quoteId}&userId=${this.user.user_ligin_id}&tenantId=${this.tenantId}&impresora=${this.printer}&puerto_impresora=${this.portPrinter}&reimpreso=${reimpreso}`
          );*/

          this.setOverlay(false);
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },

    storeRecip(item) {
      if (item.recip.length == 8) {
        this.setUrl(`cotizacion-item/${this.dataQuotation.quoteId}`);
        this.requestApi({
          method: "PUT",
          data: {
            itemSeqId: item.seqId,
            recip: item.recip,
          },
        }).then((res) => {});
      }
    },

    envioExpress() {
      if (this.expressShippingPartyId == null) {
        this.setUrl(
          `cotizacion/${this.dataQuotation.quoteId}/delete-express-shipping`
        );
        this.requestApi({
          method: "PATCH",
          data: {
            quoteId: this.dataQuotation.quoteId,
            partyId: this.expressShippingPartyId,
            orderId: this.orderId,
          },
        }).then((res) => {
          console.log(res);
          this.expressShippingPartyId = null;
        });
      } else if (this.expressShippingPartyId != null) {
        Vue.swal({
          html: "Esta seguro de asignar el envío a la persona seleccionada?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cerrar",
          ...this.paramAlertQuestion,
        }).then((result) => {
          if (result.isConfirmed) {
            this.setUrl(
              `cotizacion/${this.dataQuotation.quoteId}/express-shipping`
            );
            this.requestApi({
              method: "PATCH",
              data: {
                quoteId: this.dataQuotation.quoteId,
                partyId: this.expressShippingPartyId,
                orderId: this.orderId,
              },
            }).then((res) => {
              console.log(res);
            });
          } else {
            this.expressShippingPartyId = null;
          }
        });
      }
    },

    downloadQuote() {
      this.setOverlay(true);
      this.setUrl("estado-cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          typeStore: "downloadQuote",
          quoteId: this.dataQuotation.quoteId,
        },
      })
        .then((res) => {
          let a = document.createElement("a");
          a.href =
            "data:" +
            res.data.res.msg.ContentType +
            ";base64," +
            res.data.res.msg.datos;
          a.download = res.data.res.msg.nombreArchivo;
          a.click();

          this.setOverlay(false);
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },

    applyManualPayment(payment) {
      Vue.swal({
        html: "Esta seguro de aplicar este pago a favor a la cotización?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setOverlay(true);
          this.setUrl(`estado-cotizacion`);
          this.requestApi({
            method: "POST",
            data: {
              typeStore: "applyManualPayment",
              quoteId: this.dataQuotation.quoteId,
              orderId: this.orderId,
              paymentId: payment.payment_id,
              balance: payment.balance,
            },
          })
            .then((res) => {
              this.openQutation();
              this.setOverlay(false);
            })
            .catch(() => {
              this.setOverlay(false);
            });
        }
      });
    },

    getQuoteStatus() {
      this.setOverlay(true);
      this.setUrl(`estado-cotizacion`);
      this.requestApi({
        method: "GET",
        data: {
          quoteId: this.dataQuotation.quoteId,
        },
      })
        .then((res) => {
          this.dataQuotation.status = [];
          this.dataQuotation.status = res.data._embedded.estado_cotizacion;
          this.setOverlay(false);
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },

    getPlaceToPayPaymentLink(payment) {
      //console.log(payment.max_amount)
      //alert('xxx');
      //return false;
      try {
        Vue.swal({
          html: "Está seguro de generar un pago por Placetopay?. <br />  <b>El link de pago sólo durará 20 minutos para poder usarlo</b>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cerrar",
          ...this.paramAlertQuestion,
        }).then((result) => {
          if (result.isConfirmed) {
            this.setOverlay(true);

            const { emailsCliente, cotizacion, telefsCliente, billingAddress } =
              this.dataRequestQuotation;

            const { partyId } = this.dataQuotation;

            let ps = this.productStore.find(
              (e) => e.product_store_id == cotizacion.productStoreId
            );
            let loginPlaceToPay = ps.login_place_to_pay;
            let accessKeyPlaceToPay = ps.access_key_place_to_pay;
            let endPointPlaceToPay = ps.url_place_to_pay;
            let quoteId = cotizacion.quoteId;
            this.overlayReceiver = true;
            let ip = "127.0.0.1";
            const now = moment().format();
            const characters =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            const charactersLength = characters.length;
            let valNonce = "";
            let paymentId = "";
            let documentType = "";
            let name = "";
            let surName = "";
            let items = [];
            let baseTax = 0;
            let totalTax = 0;

            if (cotizacion.partyCedula.length == 10) {
              documentType = "CI";
            } else if (cotizacion.partyCedula.length == 13) {
              documentType = "RUC";
            } else {
              documentType = "PPN";
            }

            let arrNombres = cotizacion.partyNombres.split(" ");

            if (arrNombres.length == 4) {
              name = arrNombres[0] + " " + arrNombres[1];
              surName = arrNombres[2] + " " + arrNombres[3];
            } else if (arrNombres.length == 3) {
              name = arrNombres[0] + " " + arrNombres[1];
              surName = arrNombres[2];
            } else {
              name = arrNombres[0];
              surName = arrNombres[1];
            }

            for (let i = 0; i < 30; i++)
              valNonce += characters.charAt(
                Math.floor(Math.random() * charactersLength)
              );

            for (let i = 0; i < 10; i++)
              paymentId += characters.charAt(
                Math.floor(Math.random() * charactersLength)
              );

            fetch("https://api.ipify.org/")
              .then((r) => r.text())
              .then((res) => {
                ip = res;
              });

            let totalPago = 0;

            if (payment.max_amount != "" && payment.max_amount > 0) {
              if (
                ["PLACE_TO_PAY_CC", "PLACE_TO_PAY_DC"].includes(
                  payment.payment_method_type_id
                )
              ) {
                let totalPagado = cotizacion.payments.reduce(
                  (a, b) => a + parseFloat(b.max_amount),
                  0
                );

                let diferencia =
                  parseFloat(this.paymentAmounts.total) - totalPagado;

                if (diferencia <= 0) {
                  alert(
                    "Verifique que el monto de los pagos sea exacto hasta completar el total de la cotización"
                  );
                  this.setOverlay(false);
                }
              }

              let cantItems = 0;
              let cantItemsIva = 0;
              for (let p of cotizacion.items) {
                let subTotalPrice = parseFloat(p.precio - p.valor_descuento);

                if (p.pctImpuesto > 0) {
                  baseTax += subTotalPrice * p.cantidad;
                  totalTax +=
                    subTotalPrice * (p.pctImpuesto / 100) * p.cantidad;
                  cantItemsIva++;
                }

                cantItems++;

                let tax_value =
                  subTotalPrice * (p.pctImpuesto / 100) * p.cantidad;
                items = [];

                /**
                 * 
                 items.push({
                  sku: p.productId,
                  name: p.descripcion,
                  qty: p.cantidad.toString(),
                  price: parseFloat(subTotalPrice.toFixed(2)),
                  tax: parseFloat(tax_value.toFixed(2)),
                });

                ***/
              }

              if (payment.max_amount < this.paymentAmounts.total) {
                if (cantItemsIva > 0 && cantItems == cantItemsIva) {
                  baseTax = (payment.max_amount / 1.15).toFixed(2);
                  totalTax = (payment.max_amount - baseTax).toFixed(2);
                } else {
                  alert(
                    "No se puede ingresar pagos parciales de esta cotización"
                  );
                  this.setOverlay(false);
                }
              }

              totalPago = payment.max_amount;
            } else {
              for (let p of cotizacion.items) {
                let subTotalPrice = parseFloat(p.precio - p.valor_descuento);

                if (p.pctImpuesto > 0) {
                  baseTax += subTotalPrice * p.cantidad;
                  totalTax +=
                    subTotalPrice * (p.pctImpuesto / 100) * p.cantidad;
                }

                let tax_value =
                  subTotalPrice * (p.pctImpuesto / 100) * p.cantidad;

                items.push({
                  sku: p.productId,
                  name: p.descripcion,
                  qty: p.cantidad.toString(),
                  price: parseFloat(subTotalPrice.toFixed(2)),
                  tax: parseFloat(tax_value.toFixed(2)),
                });
                totalPago = this.paymentAmounts.total;
              }
            }

            let options = {
              method: "POST",
              url: endPointPlaceToPay,
              headers: { "Content-Type": "application/json" },
              data: {
                locale: "es_EC",
                auth: {
                  login: loginPlaceToPay,
                  tranKey: btoa(
                    sha1(valNonce + now + accessKeyPlaceToPay, {
                      asString: true,
                    })
                  ),
                  nonce: btoa(valNonce),
                  seed: now,
                },
                buyer: {
                  document: cotizacion.partyCedula,
                  documentType: documentType,
                  name: name,
                  surname: surName,
                  email: emailsCliente.find(
                    (e) => e.contactmechid == cotizacion.emailId
                  ).email,
                  mobile: telefsCliente.find(
                    (e) => e.contactmechid == cotizacion.telefonoId
                  ).phone,
                },
                payment: {
                  reference: `${
                    this.tenantId
                  }-${paymentId.toUpperCase()}-${quoteId}`,
                  description: "Pago de cotización",
                  amount: {
                    currency: "USD",
                    total: totalPago,
                    taxes: [
                      {
                        kind: "valueAddedTax",
                        amount: totalTax,
                        base: baseTax,
                      },
                    ],
                    details: null,
                  },
                  items: [],
                  subscribe: false,
                  allowPartial: false,
                },
                expiration: moment().add(20, "minutes").format(),
                returnUrl:
                  process.env.VUE_APP_URL_ECOMMERCE + "/pago-placetopay",
                ipAddress: ip,
                userAgent: "PlacetoPay Sandbox", // NAVEGADOR QUE USA LA PERSONA
                skipResult: false,
              },
            };

            if (this.whitShipping) {
              let arrNombres = this.attnName.split(" ");

              if (arrNombres.length == 4) {
                name = arrNombres[0] + " " + arrNombres[1];
                surName = arrNombres[2] + " " + arrNombres[3];
              } else if (arrNombres.length == 3) {
                name = arrNombres[0] + " " + arrNombres[1];
                surName = arrNombres[2];
              } else {
                name = arrNombres[0];
                surName = arrNombres[1];
              }

              let ds = billingAddress.find(
                (e) => e.contactmechid == this.directionSelected
              );

              options.data.payment.shipping = {
                name: name,
                surname: surName,
                mobile: this.tlfAttnName,
                address: {
                  street: ds.calleprincipal,
                  city: ds.ciudad,
                  state: ds.provincia,
                  country: "Ecuador",
                },
              };
            }

            options.data.payment.items = items;

            options.data.fields = [
              {
                quoteId,
                partyId,
                displayOn: "none",
              },
            ];
            /* console.log(options);
            alert('xddfdfdf');
            this.setOverlay(false);
            return false;*/

            axios
              .request(options)
              .then((res) => {
                const { requestId, processUrl } = res.data;

                if (res.status == 200 && res.data.status.status == "OK") {
                  this.setUrl(`cotizacion/${quoteId}/guarda-pago-placetopay`);
                  this.requestApi({
                    method: "PATCH",
                    data: {
                      quoteId,
                      user: this.user.user_ligin_id,
                      manual_ref_num: options.data.payment.reference,
                      max_amount: totalPago, //this.paymentAmounts.total,
                      payment_method_type_id: payment.payment_method_type_id,
                      tenantId: this.tenantId,
                      requestId,
                    },
                  })
                    .then((result) => {
                      this.copyClipBoard(processUrl);
                      this.openQutation();
                      this.setOverlay(false);

                      this.alertNotification({
                        param: {
                          html: result.data.detail.msg,
                        },
                      });

                      Vue.swal({
                        icon: "info",
                        html: `Link de pago copiado en el portapapeles: <br /> <b>${processUrl}</b> `,
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                      this.setOverlay(false);
                    });
                }

                console.log("respuesta placetopay", res);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
      } catch (err) {
        console.log(err);
      }
    },

    copyClipBoard(str) {
      let el = document.createElement("textarea");
      el.value = str;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    abrirDialogCupones() {
      this.dialogCupones = true;
    },
    guardarCupon() {
      if (this.$refs.formCupon.validate() == false) {
        return false;
      }
      this.setOverlay(true);
      this.setUrl("quote-promo-code");
      this.requestApi({
        method: "POST",
        data: {
          quote_id: this.dataQuotation.quoteId,
          accion: "guardarQuotePromoCode",
          product_promo_code_id:
            this.codigoPromo == null ? this.cuponCotizacion : this.codigoPromo,
          codigo_externo: this.codigoPromo == null ? "" : this.codigoExterno,
        },
      })
        .then((res) => {
          this.dataRequestQuotation.cupones = this.cupones;
          this.setOverlay(false);
          this.openQutation();
          this.dialogCupones = false;
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },
    borrarCodigoPromo(item) {
      this.setOverlay(true);
      this.setUrl("quote-promo-code");
      this.requestApi({
        method: "POST",
        data: {
          quote_id: this.dataQuotation.quoteId,
          accion: "borrarQuotePromoCode",
          product_promo_code_id: item.product_promo_code_id,
          codigo_externo: item.codigo_externo,
        },
      })
        .then((res) => {
          //this.dataQuotation.status = [];
          //this.dataQuotation.status = res.data._embedded.estado_cotizacion;
          this.dataRequestQuotation.cupones = this.cupones;
          this.setOverlay(false);
          this.openQutation();
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof this.$route.params.work_effort_id != "undefined") this.tab = 1;
    });
    this.actionData = this.actionQuotation;
  },
  created() {
    this.setDrawer(false);
    //this.setOverlay(true);
    this.dataPrimaryPos().then(() => {
      this.openQutation();
      //this.setOverlay(false);
    });
  },
  beforeUpdate() {
    if (this.actionData == 3) this.actionData = this.actionQuotation;
  },
  unmounted() {
    this.$refs.cam.forEach((e) => {
      e.stop();
    });
  },
};
</script>
<style>
.swal2-input {
  height: 1.625em;
  margin: 0;
}

.vue2leaflet-map {
  z-index: 150 !important;
}

.v-input--selection-controls {
  padding: 0;
  margin: 0;
}

#header-buttons::-webkit-scrollbar {
  width: 8px; /* Tamaño del scroll en vertical */
  height: 8px;
}
</style>
